import axios from "axios";
import { createContext, useReducer, useContext, ReactNode } from "react";
import {
  modelSubscriptionsReducer,
  initialState,
} from "reducers/modelSubscriptionsReducer";
import {
  clearActionFailure,
  clearActionSuccess,
  setLoading,
  setFailure,
  Meta,
} from "models/util";
import { convertFiltersToQueryString } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";

const ModelSubscriptionsContext = createContext(initialState);

interface Props {
  children?: ReactNode;
}

interface ModelSubscriptionProps {
  catalogId: string;
  page: number;
  take: number;
  filters?: any;
  sort?: any[];
}

export const ModelSubscriptionsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(modelSubscriptionsReducer, initialState);

  const getModelSubscriptions = ({
    catalogId,
    page = 1,
    take = 20,
    filters = [],
    sort = [],
  }: ModelSubscriptionProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/model_subscriptions?page=${page}&items_per_page=${take}${f}`,
      )
      .then((res) => {
        setModelSubscriptions(processDateObjects(res.data.data), res.data.meta);
      })
      .catch(() => {
        setFailure(dispatch, "Error getting model subscriptions");
      });
  };

  const setModelSubscriptions = (modelSubscriptions: any, meta: Meta) => {
    dispatch({
      type: "success",
      results: { modelSubscriptions, metaModelSubscriptions: meta },
    });
  };

  const removeModelSubscriptionById = async (
    catalogId: string,
    subscriptionId: string,
  ) => {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/model_subscriptions/${subscriptionId}`,
    );
    dispatch({
      type: "actionSuccess",
      message: "Model Subscription has been removed!",
    });
    setTimeout(
      () => getModelSubscriptions({ catalogId, page: 1, take: 20 }),
      2000,
    );
    setTimeout(() => clearActionSuccess(dispatch), 5000);
  };

  const editModelSubscription = (
    payload: any,
    catalogId: string,
    subscriptionId: string,
  ) => {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/model_subscriptions/${subscriptionId}`,
        payload,
      )
      .then(() => {
        dispatch({ type: "resetModelSubscriptions" });
        dispatch({
          type: "actionSuccess",
          message: "Model Subscription has been updated!",
        });
        setTimeout(
          () => getModelSubscriptions({ catalogId, page: 1, take: 20 }),
          2000,
        );
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      })
      .catch((error) => {
        const errorMsg =
          error.response.data?.errors[0]?.message ||
          "Error updating model subscription";
        dispatch({ type: "failure", error: errorMsg });
        setTimeout(() => clearActionFailure(dispatch), 5000);
        return errorMsg;
      });
  };

  const createModelSubscription = (payload: any, catalogId: string) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/model_subscriptions`,
        payload,
      )
      .then(() => {
        dispatch({
          type: "actionSuccess",
          message: "Model Subscription has been created!",
        });
        setTimeout(
          () => getModelSubscriptions({ catalogId, page: 1, take: 20 }),
          2000,
        );
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      })
      .catch((error) => {
        const errorMsg =
          error.response.data?.errors[0]?.message ||
          "Error creating model subscription";
        dispatch({ type: "failure", error: errorMsg });
        setTimeout(() => clearActionFailure(dispatch), 5000);
        return errorMsg;
      });
  };

  const resetModelSubscriptions = () => {
    dispatch({ type: "request" });
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    modelSubscriptions: state.modelSubscriptions,
    meta: state.meta,
    metaModelSubscriptions: state.metaModelSubscriptions,
    isSuccess: state.isSuccess,
    message: state.message,
    getModelSubscriptions,
    removeModelSubscriptionById,
    setModelSubscriptions,
    editModelSubscription,
    createModelSubscription,
    resetModelSubscriptions,
    setLoading,
    setFailure,
  };

  return (
    <ModelSubscriptionsContext.Provider value={value}>
      {children}
    </ModelSubscriptionsContext.Provider>
  );
};

const useModelSubscriptions = () => {
  const context = useContext(ModelSubscriptionsContext);
  if (context === undefined) {
    throw new Error(
      "useModelSubscriptions must be used within ModelSubscriptionsProvider",
    );
  }
  return context;
};

export default useModelSubscriptions;
