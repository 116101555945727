import { useEffect, useState } from "react";
import { TagsSearch } from "./tagsSearch";
import axios from "axios";
import { TagsDisplay } from "./tagsDisplay";
import { generateSortQueryParam } from "models/util";

interface TagsViewProps {
  onSelectionChange: (args: string[] | undefined) => void;
}

const TAGS_COUNT = "taggings_count";
const TAGS_SORT_DIR = "desc";

export const TagsView = (props: TagsViewProps) => {
  const [topTags, setTopTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState<string[]>();

  useEffect(() => {
    (async function () {
      handleTopTags();
    })();
  }, []);

  useEffect(() => {
    if (!selectedTags) return;
    props.onSelectionChange(selectedTags);
  }, [selectedTags]);

  const handleTopTags = async () => {
    try {
      const res = await getTopTags();
      setTopTags(res.data.data);
    } catch (e) {
      console.error(e);
    }
  };
  const onSelectionChange = (t: string) => {
    const _selectedTags = selectedTags || [];
    if (selectedTags?.includes(t)) {
      setSelectedTags([..._selectedTags.filter((e) => e !== t)]);
    } else {
      setSelectedTags([..._selectedTags, t]);
    }
  };

  const onSelectionSearchChange = (tags: any[]) => {
    setSelectedTags(
      tags.map((t) => {
        if (typeof t === "string") {
          return t;
        } else if (typeof t === "undefined") {
          return undefined;
        } else {
          return t.name;
        }
      }),
    );
  };
  const getTags = async (page: 1, take = 10, f: any, sort: any) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/tags?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`,
    );
  };
  const getTopTags = async () => {
    return getTags(1, 10, "", [{ field: TAGS_COUNT, dir: TAGS_SORT_DIR }]);
  };

  return (
    <section className="tags-view pb-2">
      <div className="card mb-2">
        <div className="card-body">
          <TagsSearch
            onSelectionChange={onSelectionSearchChange}
            selectedTags={selectedTags}
          />
          <p
            style={{ clear: "both", display: "block" }}
            className="pt-2 pb-2 light-gray"
          >
            <span>
              <small>Top 10</small>
            </span>
          </p>
          <TagsDisplay
            onSelectionChange={onSelectionChange}
            displayedTags={topTags}
            selectedTags={selectedTags}
            onDeleted={handleTopTags}
          />
        </div>
      </div>
    </section>
  );
};
