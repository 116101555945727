import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

const ViewSubClassification = (props: any) => {
  const { subClassification } = props;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <p className="mb-0">{subClassification?.name}</p>
        </div>
      </div>
      <NavLink
        to={`/subclassifications/edit/${subClassification?.id}`}
        replace={true}
      >
        <button
          type="button"
          className="save-ui btn btn-primary btn-block fw-bolder float-end"
        >
          <EditIcon fontSize="small" />
          Edit
        </button>
      </NavLink>
    </>
  );
};

export default ViewSubClassification;
