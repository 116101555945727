import { useState } from "react";
import { useForm } from "react-hook-form";
import useBrandFamilies from "context/brandsFamiliesContext";
import { SaveButton } from "components/shared/saveButton";

const AddBrandFamily = (props: any) => {
  const { createBrandFamily } = useBrandFamilies();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  return (
    <form
      className={errors.name ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((data) => {
        createBrandFamily(data).then((error: any) => {
          if (!error) {
            props.onExit();
          }
        });
      })}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <input
            className="form-control"
            {...register("name", { required: true })}
            required
          />
          {errors.name && (
            <div className="invalid-feedback">
              <p className="warning-icon">This field is required.</p>
            </div>
          )}
        </div>
      </div>

      <SaveButton />
    </form>
  );
};

export default AddBrandFamily;
