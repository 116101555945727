import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

const ViewBrandCategory = (props: any) => {
  const { brandCategory } = props;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <p className="mb-0">{brandCategory?.name}</p>
        </div>
        <div className="card-body">
          <label className="form-label">Brand</label>
          <p className="mb-0">{brandCategory?.brand?.name}</p>
        </div>

        <div className="card-body">
          <label className="form-label">Category</label>
          <p className="mb-0">{brandCategory?.category?.name}</p>
        </div>
      </div>
      <NavLink
        to={`/brand-categories/edit/${brandCategory?.id}`}
        replace={true}
      >
        <button
          type="button"
          className="save-ui btn btn-primary btn-block fw-bolder float-end"
        >
          <EditIcon fontSize="small" />
          Edit
        </button>
      </NavLink>
    </>
  );
};

export default ViewBrandCategory;
