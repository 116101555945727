import { useState, useEffect } from "react";
import { ProductDetails } from "components/productPackshotsIngestion/productDetails";
import * as Papa from "papaparse";

export const ProductPackshotsIngestion = () => {
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [fullNameHeader, setFullNameHeader] = useState<string>("");
  const [matchingField, setMatchingField] = useState<string>("");
  const [fileRows, setFileRows] = useState<string[][]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [confirmedRows, setConfirmedRows] = useState<Set<number>>(new Set());
  const [alreadyExistsRows, setAlreadyExistsRows] = useState<Set<number>>(
    new Set(),
  );
  const [notMatchedRows, setNotMatchedRows] = useState<Set<number>>(new Set());

  const [modalImage, setModalImage] = useState<{
    url: string;
    key: string;
  } | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");

  const handleConfirm = () => {
    console.log(`Confirming row at index: ${currentIndex}`);
    setConfirmedRows((prev) => {
      const newSet = new Set(prev);
      newSet.add(currentIndex);
      console.log(`Confirmed rows: ${Array.from(newSet)}`);
      return newSet;
    });
    handleNext();
  };

  const handleNotMatched = () => {
    if (notMatchedRows.has(currentIndex)) {
      console.log(
        `This is the second time reviewing row at index: ${currentIndex} as not matched`,
      );
    } else {
      console.log(`Marking row at index: ${currentIndex} as not matched`);
      setNotMatchedRows((prev) => {
        const newSet = new Set(prev);
        newSet.add(currentIndex);
        console.log(`Rows marked as not matched: ${Array.from(newSet)}`);
        return newSet;
      });
      // handleNext();
    }
  };

  const handleAlreadyExists = () => {
    setAlreadyExistsRows((prev) => {
      const newSet = new Set(prev);
      newSet.add(currentIndex);
      return newSet;
    });
    handleNext();
  };

  const handlePrevious = () => {
    setCurrentIndex((prev) => Math.max(0, prev - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prev) => Math.min((fileRows.length || 1) - 1, prev + 1));
  };

  const handleImageClick = (url: string) => {
    setModalImageUrl(url);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageUrl("");
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];

      // Store file in state
      setUploadedFile(file);

      console.log("File name:", file.name);

      const reader = new FileReader();
      reader.onload = (e) => {
        const contents = e.target?.result;
        if (typeof contents === "string") {
          const rows = contents.split("\n").map((row) => row.split(","));
          setFileRows(rows);
        }
      };
      reader.readAsText(file);
    } else {
      console.log("No file selected.");
    }
  };

  useEffect(() => {
    if (uploadedFile) {
      Papa.parse(uploadedFile, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: function (results: { data: any[] }) {
          console.log("Parsed Results:", results.data);
          setFileRows(results.data);
        },
      });
      setMatchingField("UUID"); // Assumption that this field will always be in CSV file so it's default and we match currently only by uuid value with BB data.
      setFullNameHeader("UUID"); // Assumption that this field will always be in CSV file so it's default.
    }
  }, [uploadedFile]);

  const exportUploadedRows = () => {
    // TODO
  };

  const exportAlreadyExistsRows = () => {
    // TODO
  };

  return (
    <>
      <div className="container mt-2">
        {!uploadedFile && (
          <>
            <div className="row">
              <div className="col-12 p-3">
                <h2 className="mb-3">Products Packshots Ingestion (beta)</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-5 p-3">
                <h2 className="mb-3">Upload CSV File</h2>
                <form>
                  <div className="mb-3">
                    <input
                      type="file"
                      name="csvFile"
                      accept=".csv"
                      className="form-control"
                      onChange={handleFileUpload}
                    />
                  </div>
                </form>
                <div className="mt-3">
                  <p>
                    Need a template? Download the sample CSV file to ensure
                    correct format:
                  </p>
                  <a
                    href={`${process.env.REACT_APP_API_BASE_URL}/imports/packshots_ingestion.csv`}
                    className="btn btn-primary"
                    download
                  >
                    Download CSV Template
                  </a>
                </div>
              </div>
              <div className="col-4 p-3">
                <div className="card mb-3">
                  <div className="card-body">
                    <p>
                      Upload a CSV file containing product information.
                      Currently, this iteration focuses on packshots upload.
                      Creating products is not yet possible but will be
                      available in future versions.
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <p>Required columns:</p>
                    <ul>
                      <li>
                        UUID - Currently the only field for matching with
                        Brandbank data.
                      </li>
                      <li>
                        Packshot URLs (at least one, up to six). The column name
                        should be in the format 'Packshot 1', 'Packshot 2', ...,
                        'Packshot 6'.
                      </li>
                    </ul>
                    All additional fields from CSV are just to help you make
                    sure that correct packshot is uploaded to correct Product.
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* {uploadedFile &&
        <div className="row border-bottom">
          <div className="col-6">
            <div className="mb-2 row">
              <div className="col-4">
                <label htmlFor="headerSelect" className="form-label">Identification header:</label>
                <select id="headerSelect" className="form-select" onChange={(e) => setFullNameHeader(e.target.value)}>
                  <option value="">Select a header</option>
                  {fileRows.length > 0 && Object.keys(fileRows[0]).map((header) => (
                    <option key={header} value={header} selected={header === fullNameHeader}>{header}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="col-4">
              <label htmlFor="matchingFieldSelect" className="form-label">Matching Field:</label>
              <select id="matchingFieldSelect" className="form-select" onChange={(e) => setMatchingField(e.target.value)}>
              {fileRows.length > 0 && Object.keys(fileRows[0]).map((header) => (
                  <option key={header} value={header} selected={header === matchingField}>{header}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      } */}

      <div className="container-fluid mt-3">
        {fileRows && fileRows.length > 0 && (
          <div className="row">
            <div className="col-2 vh-100 overflow-auto">
              <div className="list-group h-100">
                {fileRows.map((row: any, index: number) => (
                  <button
                    key={index}
                    className={`list-group-item list-group-item-action ${index === currentIndex ? "active" : ""}`}
                    onClick={() => setCurrentIndex(index)}
                  >
                    {confirmedRows.has(index) && (
                      <span className="badge bg-success me-2">✓</span>
                    )}
                    {alreadyExistsRows.has(index) && (
                      <span className="badge bg-warning me-2">✗</span>
                    )}
                    {notMatchedRows.has(index) && (
                      <span className="badge bg-danger me-2">X</span>
                    )}
                    <span className="small">{row[fullNameHeader]}</span>
                  </button>
                ))}
              </div>
            </div>
            <div className="col-10">
              <div className="row">
                <div className="col-12">
                  <div className="row mb-1">
                    <div className="col-md-3">
                      <h5 className="card-title mb-1">Ingested data</h5>
                      <table className="table table-striped table-bordered table-sm">
                        <tbody>
                          {Object.entries(fileRows[currentIndex]).map(
                            ([key, value]) => {
                              return (
                                <tr key={key}>
                                  <td className="text-secondary">
                                    <strong>{key}</strong>
                                  </td>
                                  <td className="text-secondary">
                                    {key === "Packshot 1" ? (
                                      <img
                                        src={value as string}
                                        alt="Packshot 1"
                                        className="img-fluid mt-2"
                                        onClick={() =>
                                          handleImageClick(value as string)
                                        }
                                        style={{
                                          maxWidth: "100%",
                                          cursor: "pointer",
                                          border:
                                            "1px solid rgba(221, 221, 221, 0.5)",
                                        }}
                                      />
                                    ) : (
                                      <span
                                        className={
                                          value ? "text-muted" : "text-danger"
                                        }
                                      >
                                        {(value as React.ReactNode) || "N/A"}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            },
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-9">
                      <ProductDetails
                        row={fileRows[currentIndex]}
                        alreadyExists={alreadyExistsRows.has(currentIndex)}
                        alreadyUploaded={confirmedRows.has(currentIndex)}
                        notMatched={notMatchedRows.has(currentIndex)}
                        onNotMatched={() => handleNotMatched()}
                        onAleadyExists={() => handleAlreadyExists()}
                        onUploaded={() => handleConfirm()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Modal for larger image
      <Modal show={isModalOpen} onHide={closeModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Packshot review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalImageUrl && 
            <img src={modalImageUrl} alt="Larger Packshot" className="img-fluid" />
          }
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};
