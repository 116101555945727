import { useState, useEffect } from "react";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { gridSettings, initialSort } from "configs/gridSettings";
import { GuidCell } from "components/grid/GuidCell";
import useProductCatalogs from "context/productCatalogContext";
import { ActionColumns } from "components/grid/action-column";
import { FullPageLoader } from "components/shared/fullPageLoader";
import { initialDataState } from "configs/initialDataState";
import { NavLink, useSearchParams } from "react-router-dom";
import ErrorToast from "components/toaster/errorToastr";
import Toast from "components/toaster/toastr";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import SWDialog from "components/shared/dialog";
import { CopiableCell } from "components/grid/CopiableCell";
import { RangeFilterCell } from "components/grid/DateRangeFilterCell";
import useProductCatalogProducts from "context/productCatalogProductsContext";
import { PermissionGate } from "shared/functions/permissionGate";

export const ProductCatalogsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const {
    isLoading,
    productCatalogs,
    meta,
    getProductCatalogs,
    isSuccess,
    message,
    error,
    removeProductCatalogById,
  } = useProductCatalogs();
  const { resetProducts } = useProductCatalogProducts();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [sort, setSort] = useState<Array<SortDescriptor>>(initialSort);

  useEffect(() => {
    resetProducts();
    const pageNumber = searchParams.get("pageNumber") || "1";
    const pageSize = searchParams.get("pageSize") || "20";
    const _pageNumber = parseInt(pageNumber, 10);
    const _pageSize = parseInt(pageSize, 10);
    dataState.take = _pageSize;
    dataState.skip = (_pageNumber - 1) * _pageSize;
    dataState.isInitial = false;
    setDataState(dataState);
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(
      `?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`,
    );
    getProductCatalogs({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
    });
  }, [dataState]);

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  };

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getProductCatalogs({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  };

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getProductCatalogs({
        page: page,
        take: dataState.take,
        filters: filter?.filters,
        sort: sort,
      });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const removeProductCatalog = () => {
    setIsDialogOpen(false);
    removeProductCatalogById(deleteId);
  };

  return (
    <div>
      <SWDialog
        open={isDialogOpen}
        confirmText="Delete"
        text="Are you sure that you want to delete this Customer Product List?"
        onConfirm={() => removeProductCatalog()}
        onClose={() => setIsDialogOpen(false)}
      />
      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}
      {productCatalogs && meta ? (
        <Grid
          filter={filter}
          onFilterChange={filterChange}
          {...gridSettings}
          className="k-grid-container"
          data={productCatalogs}
          total={meta.total_count}
          pageSize={dataState.take}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState as any);
          }}
          skip={meta.page_size * meta.current_page - meta.page_size}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            sortChanged(e.sort);
          }}
        >
          <GridColumn
            field="id"
            sortable={false}
            title="UUID"
            width="110"
            cell={GuidCell}
          />
          <GridColumn
            field="name"
            title="Name"
            width="500"
            cell={(o: GridCellProps) => (
              <td>
                <NavLink
                  to={`/customer-product-lists/products/${o.dataItem.id}?pageNumber=1&pageSize=20`}
                  replace={true}
                >
                  {o.dataItem.name}
                </NavLink>
              </td>
            )}
          />
          <GridColumn
            field="products_total_count"
            sortable={false}
            title="Products"
            width="250"
            cell={CopiableCell}
            filterable={false}
          />
          <GridColumn
            field="created_at"
            width="240"
            filterCell={RangeFilterCell}
            title="Created at"
          />
          <GridColumn
            field="updated_at"
            width="240"
            filterCell={RangeFilterCell}
            title="Updated at"
          />
          <GridColumn
            field="actions"
            sortable={false}
            title="Actions"
            filterable={false}
            width="200px"
            cell={(o) => (
              <PermissionGate permission="write" resource="product_catalogs">
                <ActionColumns
                  id={o.dataItem.id}
                  route="customer-product-lists/product"
                  onDelete={(id: string) => {
                    setDeleteId(id);
                    setIsDialogOpen(true);
                  }}
                />
              </PermissionGate>
            )}
          />
        </Grid>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
};
