import { GridCellProps } from "@progress/kendo-react-grid";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

export const LockedPhotoCell = (props: GridCellProps) => {
  const { colSpan, className, style, ariaColumnIndex, field, dataItem } = props;

  return (
    <td
      colSpan={colSpan}
      className={className}
      style={style}
      aria-colindex={ariaColumnIndex}
      role={"gridcell"}
    >
      {dataItem.thumbnail_url ? (
        <img src={dataItem.thumbnail_url} className="grid-thumbnail" />
      ) : (
        <div style={{ height: "36px", width: "36px", float: "left" }}>
          <NoPhotographyIcon></NoPhotographyIcon>
        </div>
      )}
    </td>
  );
};
