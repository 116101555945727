import { Drawer } from "@mui/material";
import axios from "axios";
import CommonLayout from "components/layouts/common-layout";
import { ModelSubscriptionsTable } from "components/modelSubscriptions/modelSubscriptionsTable";
import { ModelSubscriptionsProvider } from "context/modelSubscriptionsContext";
import { FormMode } from "models/util";
import { ProductCatalog } from "models/product_catalog";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import EditModelSubscription from "modules/productCatalogs/modelSubscriptions/editModelSubscription";
import CreateModelSubscription from "modules/productCatalogs/modelSubscriptions/createModelSubscription";
import { ModelListsProvider } from "context/modelListsContext";

interface ModelSubscriptionsPageProps {
  mode: FormMode;
  catalog?: ProductCatalog;
}

const ModelSubscriptionsPage = (props: ModelSubscriptionsPageProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Model Subscription");
  let { editModelSubscriptionId, catalogId } = useParams();
  const [modelSubscription, setModelSubscription] = useState<any>({});
  const [catalog, setCatalog] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode);
  const navigate = useNavigate();

  useEffect(() => {
    switch (mode) {
      case FormMode.Create:
        setDrawerTitle("Add Model Subscription");
        break;
      case FormMode.Edit:
        setDrawerTitle("Edit Model Subscription");
        break;
      default:
        setDrawerTitle("Model Subscription");
    }
  }, [mode]);

  useEffect(() => {
    if (catalogId && editModelSubscriptionId) {
      setMode(FormMode.Edit);
    }
  }, [catalogId, editModelSubscriptionId]);

  useEffect(() => {
    if (catalogId) {
      setCatalog(null);
      getProductCatalogById(catalogId).then((res: any) => {
        setCatalog(res.data.data);
      });
    }
  }, [catalogId]);

  const getProductCatalogById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/product_catalogs/${id}`);
  };

  const exitToCatalog = () => {
    setMode(FormMode.None);
    navigate(`/customer-product-lists/products/${catalogId}`, {
      replace: true,
    }); // Poprawiona ścieżka
  };

  const onExit = () => {
    setMode(FormMode.None);
    navigate(`/customer-product-lists/model-subscriptions/${catalogId}`, {
      replace: true,
    }); // Poprawiona ścieżka
  };

  return (
    <CommonLayout>
      <ModelSubscriptionsProvider>
        <h2 className="float-start">
          {catalogId && (
            <ArrowBackIcon
              onClick={exitToCatalog}
              className="primary-col pointer"
            />
          )}
          {catalog ? (
            <span className="mx-3">
              <span className="light-gray small">Model Subscriptions</span>
              <span className="larger">
                {catalog?.name || "No ID available"}
              </span>{" "}
              {/* Upewnij się, że wyświetlasz odpowiednią nazwę */}
            </span>
          ) : (
            <>Model Subscriptions</>
          )}
        </h2>

        <button
          onClick={() => {
            setMode(FormMode.Create);
            navigate(
              `/customer-product-lists/model-subscriptions/${catalogId}/new`,
              { replace: true },
            ); // Upewnij się, że ścieżka jest poprawna
          }}
          className="btn btn-primary btn-block fw-bolder float-end"
        >
          <AddIcon /> Add Model Subscription
        </button>
        <div className="clearfix"></div>
        <ModelSubscriptionsTable />

        <Drawer
          PaperProps={{ style: { width: "600px" } }}
          anchor={"right"}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <ModelListsProvider>
            <section className="sidebar-entity p-4">
              <h2 className="float-start">
                <ArrowBackIcon
                  onClick={onExit}
                  className="primary-col pointer"
                />
                <span className="px-2">{drawerTitle}</span>
              </h2>
              <div className="clearfix" />
              {console.log("modelSubscription", modelSubscription)}
              {mode === FormMode.Edit && modelSubscription && (
                <EditModelSubscription
                  onExit={onExit}
                  modelSubscription={modelSubscription}
                />
              )}
              {mode === FormMode.Create && (
                <CreateModelSubscription onExit={onExit} />
              )}
            </section>
          </ModelListsProvider>
        </Drawer>
      </ModelSubscriptionsProvider>
    </CommonLayout>
  );
};

export default ModelSubscriptionsPage;
