import { useState, useEffect } from "react";
import useProducts from "context/productContext";
import { packshotModalStyle } from "components/shared/modalStyles";
import { Box, Modal } from "@mui/material";
import { BootstrapButton } from "components/shared/bootstrapButton";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { PackshotsCroppingModal } from "./packshotsCroppingModal";

interface PackshotsConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  productId: string;
  uploadingFiles: any;
  updatePhoto: (
    productId: string,
    photoId: string,
    formData: FormData,
  ) => Promise<void>;
}

export const PackshotsConfirmationModal = (
  props: PackshotsConfirmationModalProps,
) => {
  const { addPhotosToProduct } = useProducts();
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [croppingModalOpen, setCroppingModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(
    null,
  );
  const [currentPhoto, setCurrentPhoto] = useState<any>(null);
  const [currentProduct, setCurrentProduct] = useState<any>(null);

  useEffect(() => {
    if (props.uploadingFiles != null && props.uploadingFiles.length > 0) {
      const fileArray = Array.from(props.uploadingFiles);
      setSelectedFiles(fileArray);
    }
  }, [props.uploadingFiles]);

  const handleModalClose = () => {
    props.onClose();
  };

  const handleConfirmationBtn = () => {
    setIsUploading(true);
    uploadFiles(selectedFiles, props.productId);
  };

  const handleRemoveFile = (index: number) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };

  const uploadFiles = (photos: any, productId: string) => {
    const photosArray = Array.from(photos);
    if (photosArray.length > 0) {
      Promise.all(
        photosArray.map((photo: any) => {
          let formData = new FormData();
          formData.append("photo", photo);
          return axios
            .post(`${process.env.REACT_APP_API_URL}/photos`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              return res.data.data.id;
            });
        }),
      ).then((ids) => {
        addPhotosToProduct(productId, ids).then(() => {
          setIsUploading(false);
          handleModalClose();
        });
      });
    }
  };

  const openCroppingModal = (image: any, index: number) => {
    setCurrentImage(URL.createObjectURL(image));
    setCurrentImageIndex(index);
    setCroppingModalOpen(true);
  };

  const handleCropConfirm = async (croppedImage: Blob) => {
    if (croppedImage && currentImageIndex !== null) {
      const updatedFiles = [...selectedFiles];
      updatedFiles[currentImageIndex] = croppedImage;
      setSelectedFiles(updatedFiles);
      setCroppingModalOpen(false);
    }
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={packshotModalStyle}>
          <h2 className="normal-header">
            <ClearOutlinedIcon
              onClick={handleModalClose}
              className="primary-col pointer"
              style={{ marginTop: "-4px" }}
            />
            <span style={{ marginLeft: "100px" }}>
              {selectedFiles.length} files selected
            </span>
          </h2>
          <hr />
          <div className="card" style={{ border: "none" }}>
            <div className="card-body">
              {selectedFiles.length > 0 &&
                selectedFiles.map((file: any, index: number) => (
                  <div key={index} className="file-preview">
                    <img
                      src={URL.createObjectURL(file)}
                      alt="preview"
                      className="preview-image"
                    />
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveFile(index)}
                    >
                      ×
                    </button>
                    <button
                      className="btn btn-secondary btn-block fw-bolder file-info"
                      onClick={() => openCroppingModal(file, index)}
                    >
                      Crop
                    </button>
                    <div className="file-info">
                      <p className="file-name">{file.name}</p>
                      <p className="file-size">
                        {(file.size / 1024).toFixed(0)} KB
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {isUploading && <LinearProgress />}
          <hr />
          <BootstrapButton variant="contained" onClick={handleConfirmationBtn}>
            <label>Upload</label>
          </BootstrapButton>
        </Box>
      </Modal>

      <PackshotsCroppingModal
        isOpen={croppingModalOpen}
        onClose={() => setCroppingModalOpen(false)}
        image={currentImage}
        onCropConfirm={handleCropConfirm}
        currentImageIndex={currentImageIndex}
        selectedFiles={selectedFiles}
        currentPhoto={currentPhoto}
        currentProduct={currentProduct}
        updatePhoto={props.updatePhoto}
        setSelectedFiles={setSelectedFiles}
        closeCroppingModal={() => setCroppingModalOpen(false)}
      />
    </>
  );
};
