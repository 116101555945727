import { GridCellProps } from "@progress/kendo-react-grid";

export const DimensionsCell = (props: GridCellProps) => {
  const { dataItem, field } = props;
  const dimensions = dataItem.dimensions;
  const isMm = field === "dimensions_mm";

  if (!dimensions) return <td>-</td>;

  const formatValue = (value: string) => Number(value).toFixed(2);

  return (
    <td>
      <div className="dimensions-container">
        <div>
          H: {formatValue(isMm ? dimensions.height_mm : dimensions.height_inch)}
        </div>
        <div>
          W: {formatValue(isMm ? dimensions.width_mm : dimensions.width_inch)}
        </div>
        <div>
          D: {formatValue(isMm ? dimensions.depth_mm : dimensions.depth_inch)}
        </div>
      </div>
    </td>
  );
};
