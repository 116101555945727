import { useState, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { gridSettings, initialSort } from "configs/gridSettings";
import { ActionColumns } from "components/grid/action-column";
import { FullPageLoader } from "components/shared/fullPageLoader";
import { initialDataState } from "configs/initialDataState";
import useLabels from "context/labelsContext";
import { GuidCell } from "components/grid/GuidCell";
import { NavLink } from "react-router-dom";
import Toast from "components/toaster/toastr";
import ErrorToast from "components/toaster/errorToastr";
import { StringArrCell } from "components/grid/StringArrCell";
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { RangeFilterCell } from "components/grid/DateRangeFilterCell";
import { useSearchPagination } from "components/hooks/useSearchPagination";
import { TagsView } from "components/tags/tagsView";
import { DropdownFilterCell } from "components/shared/dropdownFilterCell";
import { GridFilterCellProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridFilterCellProps";

const filterableEntityTypes = [
  "product",
  "brand",
  "brand category",
  "category",
  "other object",
];
const EntityTypeFilterCell: any = (props: GridFilterCellProps) => (
  <DropdownFilterCell
    {...props}
    data={filterableEntityTypes}
    defaultItem={"-"}
  />
);

export const LabelsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { isLoading, labels, meta, getLabels, isSuccess, error, message } =
    useLabels();
  const [filter, setFilter] = useState<CompositeFilterDescriptor | any>(
    undefined,
  );
  const [deleteId, setDeleteId] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [sort, setSort] = useState(initialSort);
  const [searchPageNumber, searchPageSize] = useSearchPagination();
  const [tags, setTags] = useState<any>(undefined);

  useEffect(() => {
    const d = { ...dataState };
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    getLabels({
      page: page,
      take: dataState.take,
      sort: sort,
      filters: [...currentFilters(), { field: "tag_name", value: tags }],
    });
  }, [dataState]);

  useEffect(() => {
    if (dataState.isInitial) return;
    getLabels({
      page: 1,
      take: dataState.take,
      filters: [...currentFilters(), { field: "tag_name", value: tags }],
      sort: sort,
    });
  }, [tags]);

  const currentFilters = () => {
    return filter?.filters || [];
  };

  const filterChange = (event: GridFilterChangeEvent) => {
    if (!event.filter) {
      addTagsToEventFilters(event);
      setFilter(event.filter);
    } else {
      removeTagsFromEventFilters(event);
      addTagsToEventFilters(event);
      setFilter(event.filter);
    }
  };

  const addTagsToEventFilters = (event: GridFilterChangeEvent) => {
    if (tags && tags.length > 0) {
      const tagsFilter = {
        field: "tag_name",
        value: tags,
        operator: "contains",
      } as FilterDescriptor;
      event.filter.filters
        ? event.filter.filters.push(tagsFilter)
        : (event.filter.filters = [tagsFilter]);
    }
  };

  const removeTagsFromEventFilters = (event: GridFilterChangeEvent) => {
    const filtersWithoutTags = event.filter.filters.filter((filterObj) => {
      return "field" in filterObj ? filterObj.field !== "tag_name" : true;
    });
    event.filter.filters = filtersWithoutTags;
  };

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      getLabels({ filters: filter?.filters });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getLabels({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  };

  const onTagsSelectionChange = (tagIds: string[] | undefined) => {
    setTags(tagIds);
  };

  return (
    <div>
      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}
      <TagsView onSelectionChange={onTagsSelectionChange}></TagsView>
      {labels && meta ? (
        <Grid
          {...gridSettings}
          filter={filter}
          sortable={false}
          onFilterChange={filterChange}
          data={labels}
          total={meta.total_count}
          className="k-grid-labels-container"
          pageSize={dataState.take}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState as any);
          }}
          skip={meta.page_size * meta.current_page - meta.page_size}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            sortChanged(e.sort);
          }}
        >
          <GridColumn
            field="id"
            title="UUID"
            sortable={false}
            width="110"
            cell={GuidCell}
          />
          <GridColumn
            field="name_like"
            title="Name"
            cell={(o) => (
              <td>
                <NavLink to={`/labels/${o.dataItem.id}`} replace={true}>
                  {o.dataItem.name}
                </NavLink>
                <br />
              </td>
            )}
          />
          <GridColumn
            field="entity_type"
            width="230"
            title="Entity Type"
            filterCell={EntityTypeFilterCell}
          />
          <GridColumn
            field="entity_name"
            title="Entity Name"
            cell={(o) => (
              <td>
                <NavLink
                  to={`/products/${o.dataItem.entity_id}`}
                  replace={true}
                >
                  {o.dataItem.entity_name}
                </NavLink>
                <br />
              </td>
            )}
          />
          <GridColumn
            field="tags"
            title="Tags"
            filterable={false}
            cell={StringArrCell}
          />
          <GridColumn
            field="created_at"
            width="240"
            filterCell={RangeFilterCell}
            title="Created at"
          />
          <GridColumn
            field="updated_at"
            width="240"
            filterCell={RangeFilterCell}
            title="Updated at"
          />
          <GridColumn
            field="action"
            title="Actions"
            filterable={false}
            cell={(o) => (
              <ActionColumns
                id={o.dataItem.id}
                route="labels"
                onDelete={false}
              />
            )}
          />
        </Grid>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
};
