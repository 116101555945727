import { useState, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterChangeEvent,
  GridProps,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { gridSettings, initialSort } from "configs/gridSettings";
import useModelLists from "context/modelListsContext";
import { FullPageLoader } from "components/shared/fullPageLoader";
import { initialDataState } from "configs/initialDataState";
import { GuidCell } from "components/grid/GuidCell";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import Toast from "components/toaster/toastr";
import ErrorToast from "components/toaster/errorToastr";
import { RangeFilterCell } from "components/grid/DateRangeFilterCell";
import { NavLink, useSearchParams } from "react-router-dom";
import { useSearchPagination } from "components/hooks/useSearchPagination";
import { NameValueCell } from "components/grid/NameValueCell";

export const ModelListsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { modelLists, meta, getModelLists, isSuccess, error, message } =
    useModelLists();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [config, setConfig] = useState<GridProps | any>(gridSettings);
  const [sort, setSort] = useState(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  useEffect(() => {
    const d = { ...dataState };
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(
      `?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`,
    );
    getModelLists({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  }, [dataState]);

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getModelLists({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  };

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      getModelLists({ filters: filter?.filters });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  return (
    <div>
      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}
      {modelLists && meta ? (
        <Grid
          {...config}
          data={modelLists}
          total={meta.total_count}
          pageSize={dataState.take}
          filter={filter}
          onFilterChange={filterChange}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState as any);
          }}
          skip={meta.page_size * meta.current_page - meta.page_size}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            sortChanged(e.sort);
          }}
        >
          <GridColumn
            field="id"
            title="UUID"
            width="110"
            sortable={false}
            cell={GuidCell}
          />
          <GridColumn
            field="name"
            title="Name"
            cell={(o) => (
              <td>
                <NavLink
                  to={`/model-lists/${o.dataItem.id}/products`}
                  replace={true}
                >
                  {o.dataItem.name}
                </NavLink>
              </td>
            )}
          />
          <GridColumn
            field="category"
            width="200"
            title="Category"
            filterable={true}
            cell={NameValueCell}
          />
          <GridColumn
            field="region"
            width="200"
            title="Region"
            filterable={true}
            cell={NameValueCell}
          />
          <GridColumn
            field="products_count"
            title="Products"
            filterable={false}
          />
          <GridColumn
            field="updated_at"
            width="240"
            filterCell={RangeFilterCell}
            title="Updated at"
          />
        </Grid>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
};
