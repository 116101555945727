import { createTheme } from "@mui/material/styles";

export const mainTheme = createTheme({
  typography: {
    fontFamily: "Inter",

    h2: {
      fontSize: "1.5",
      fontWeight: "700",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Inter';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Inter');
        }
      `,
    },
  },
});
