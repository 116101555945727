import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useBrandCategories from "context/brandCategoriesContext";
import { Autocomplete, TextField } from "@mui/material";
import useBrands from "context/brandsContext";
import useCategories from "context/categoriesContext";
import { SaveButton } from "components/shared/saveButton";
import LockIcon from "@mui/icons-material/Lock";

const AddBrandCategory = (props: any) => {
  const { editBrandCategory } = useBrandCategories();
  const { getBrands, brands } = useBrands();
  const { categories, getCategories } = useCategories();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      brand: props.brandCategory?.brand?.id,
      name: props.brandCategory?.name,
      category: props.brandCategory?.category?.id,
      create_label_automatically:
        props.brandCategory?.create_label_automatically,
    },
  });

  const fullName = watch("name");

  useEffect(() => {
    getBrands({ page: 1, take: 20 });
    getCategories({ page: 1, take: 20 });
  }, []);

  const getBrandForName = (searchTerm: string) => {
    getBrands({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };

  const getCategoryForName = (searchTerm: string) => {
    getCategories({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };

  return (
    brands &&
    categories && (
      <form
        className={errors.brand ? "was-validated" : "needs-validation"}
        onSubmit={handleSubmit((data: any) => {
          data.id = props.brandCategory.id;
          editBrandCategory(data).then((error: any) => {
            if (!error) {
              props.onExit();
            }
          });
        })}
        noValidate
      >
        <div className="card my-3">
          <div className="card-body">
            <label className="form-label">
              Name &nbsp; <LockIcon fontSize="inherit" />
            </label>
            <input className="form-control" disabled value={fullName} />
          </div>
          <div className="card-body">
            <Autocomplete
              onInputChange={(event, newInputValue) => {
                getBrandForName(newInputValue);
              }}
              onChange={(event: any, newValue: any | null) => {
                setValue("brand", newValue?.id);
              }}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              options={brands}
              defaultValue={props.brandCategory.brand}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.brand}
                  variant="outlined"
                  helperText={!!errors.brand && "This field is required."}
                  fullWidth
                  label="Brand"
                  InputProps={{
                    ...register("brand", {
                      required: "This field is required.",
                    }),
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="card-body">
            <Autocomplete
              onInputChange={(event, newInputValue) => {
                getCategoryForName(newInputValue);
              }}
              onChange={(event: any, newValue: any | null) => {
                setValue("category", newValue?.id);
              }}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              options={categories}
              defaultValue={props.brandCategory.category}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!errors.category}
                  variant="outlined"
                  helperText={!!errors.category && "This field is required."}
                  fullWidth
                  label="Category"
                  InputProps={{
                    ...register("category", {
                      required: "This field is required.",
                    }),
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </div>
        </div>
        <SaveButton />
      </form>
    )
  );
};

export default AddBrandCategory;
