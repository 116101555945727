import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useBrandCategories from "context/brandCategoriesContext";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import useBrands from "context/brandsContext";
import useCategories from "context/categoriesContext";
import { SaveButton } from "components/shared/saveButton";
import LockIcon from "@mui/icons-material/Lock";

const AddBrandCategory = (props: any) => {
  const { createBrandCategory } = useBrandCategories();
  const { getBrands, brands } = useBrands();
  const { categories, getCategories } = useCategories();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      brand: undefined,
      brandName: undefined,
      category: undefined,
      categoryName: undefined,
      create_label_automatically: true,
    },
  });

  useEffect(() => {
    getBrands({ page: 1, items_per_page: 20 });
    getCategories({ page: 1, items_per_page: 20 });
  }, []);

  const getBrandForName = (searchTerm: string) => {
    getBrands({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };

  const getCategoryForName = (searchTerm: string) => {
    getCategories({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };
  const brand = watch("brandName");
  const category = watch("categoryName");
  const getName = (): string => {
    if (brand && category) return `${brand}/${category}`;
    if (brand) return brand;
    if (category) return category;
    return "";
  };

  return (
    <form
      className={errors.brand ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((data) => {
        createBrandCategory(data).then((error: any) => {
          if (!error) {
            props.onExit();
          }
        });
      })}
      noValidate
    >
      <div className="card my-3">
        <div className="card-body">
          <label className="form-label">
            Name &nbsp; <LockIcon fontSize="inherit" />
          </label>
          <input className="form-control" disabled value={getName()} />
        </div>
        <div className="card-body">
          <Autocomplete
            onInputChange={(event, newInputValue) => {
              getBrandForName(newInputValue);
            }}
            onChange={(event: any, newValue: any | null) => {
              setValue("brand", newValue?.id);
              setValue("brandName", newValue?.name);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={brands}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors.brand}
                variant="outlined"
                helperText={!!errors.brand && "This field is required."}
                fullWidth
                label="Brand"
                InputProps={{
                  ...register("brand", {
                    required: "This field is required.",
                  }),
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>
        <div className="card-body">
          <Autocomplete
            onInputChange={(event, newInputValue) => {
              getCategoryForName(newInputValue);
            }}
            onChange={(event: any, newValue: any | null) => {
              setValue("category", newValue?.id);
              setValue("categoryName", newValue?.name);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={categories}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors.category}
                variant="outlined"
                helperText={!!errors.category && "This field is required."}
                fullWidth
                label="Category"
                InputProps={{
                  ...register("category", {
                    required: "This field is required.",
                  }),
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <FormControlLabel
            control={
              <Controller
                name={"create_label_automatically"}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={!!props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={"Create & assign label automatically"}
          />
        </div>
      </div>
      <SaveButton />
    </form>
  );
};

export default AddBrandCategory;
