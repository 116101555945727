import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { SaveButton } from "components/shared/saveButton";
import useModelLists from "context/modelListsContext";
import useModelSubscriptions from "context/modelSubscriptionsContext";
import { useParams } from "react-router-dom"; // Import useParams

const CreateModelSubscription = (props: any) => {
  const { createModelSubscription } = useModelSubscriptions();
  const { searchModelLists, modelLists, isLoading } = useModelLists();
  const [selectedModel, setSelectedModel] = useState<string>("");

  const { catalogId } = useParams<{ catalogId: string }>();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      model: undefined,
      active: true,
    },
  });

  useEffect(() => {
    searchModelLists({ filters: [] });
  }, []);

  const handleModelSearch = (searchTerm: string) => {
    searchModelLists({ filters: [{ field: "name", value: searchTerm }] });
  };

  const onSubmit = (data: any) => {
    createModelSubscription(
      { ...data, model_list_id: selectedModel },
      catalogId,
    ).then((error: any) => {
      if (!error) {
        props.onExit();
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <div className="card my-3">
        <div className="card-body">
          <label className="form-label">Model</label>
          <Autocomplete
            onInputChange={(event, newInputValue) => {
              handleModelSearch(newInputValue);
            }}
            onChange={(event: any, newValue: any | null) => {
              setSelectedModel(newValue?.id);
              setValue("model", newValue?.name);
            }}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={modelLists}
            loading={isLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors.model}
                variant="outlined"
                helperText={!!errors.model && "This field is required."}
                fullWidth
                label="Select Model"
                InputProps={{
                  ...register("model", {
                    required: "This field is required.",
                  }),
                  ...params.InputProps,
                }}
              />
            )}
          />
          {errors.model && (
            <div className="invalid-feedback">This field is required.</div>
          )}
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <FormControlLabel
            control={
              <Controller
                name={"active"}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={!!props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={"Active"}
          />
        </div>
      </div>
      <SaveButton />
    </form>
  );
};

export default CreateModelSubscription;
