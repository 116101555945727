import CommonLayout from "components/layouts/common-layout";
import { ImportsTable } from "components/imports/importsTable";
import { ImportsProvider } from "context/importsContext";

const ImportsPage = () => {
  return (
    <CommonLayout>
      <h2 className="float-start">Imports</h2>
      <ImportsProvider>
        <div className="clearfix"></div>
        <ImportsTable />
      </ImportsProvider>
    </CommonLayout>
  );
};

export default ImportsPage;
