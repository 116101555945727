import React, { ReactNode } from "react";
import usePermissions from "context/permissionsContext";
import { Resource, Permission } from "models/permission";

type PermissionGateProps = {
  resource: Resource;
  permission: Permission;
  children: ReactNode;
};

export function hasPermission(
  permission: string,
  resource: string,
  permissions: any,
): boolean {
  if (!permissions) {
    return false;
  }

  if (permission === "disabled" && permissions.disabled.includes(resource)) {
    return false;
  }

  return permissions[permission]?.includes(resource) ?? false;
}

export const PermissionGate: React.FC<PermissionGateProps> = ({
  resource,
  permission,
  children,
}) => {
  const { permissions } = usePermissions();

  if (!permissions) {
    return null;
  }

  const isAllowed = hasPermission(permission, resource, permissions);

  return isAllowed ? <>{children}</> : null;
};
