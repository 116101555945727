import { useState, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { gridSettings } from "configs/gridSettings";
import useExports from "context/exportsContext";
import { FullPageLoader } from "components/shared/fullPageLoader";
import { initialDataState } from "configs/initialDataState";
import { useSearchParams } from "react-router-dom";
import Toast from "components/toaster/toastr";
import ErrorToast from "components/toaster/errorToastr";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { useSearchPagination } from "components/hooks/useSearchPagination";

const initialSort: Array<SortDescriptor> = [
  { field: "created_at", dir: "desc" },
];

export const ExportsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { exports, meta, getExports, isSuccess, message, error } = useExports();
  const [filter, setFilter] = useState<CompositeFilterDescriptor | any>(
    undefined,
  );
  const [sort, setSort] = useState(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  useEffect(() => {
    const d = { ...dataState };
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(
      `?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`,
    );
    getExports({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  }, [dataState]);

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  };

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getExports({
        page: page,
        take: dataState.take,
        filters: filter?.filters,
        sort: sort,
      });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getExports({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  };

  return (
    <div>
      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}

      {exports && meta ? (
        <Grid
          {...gridSettings}
          data={exports}
          filter={filter}
          className="k-grid-container"
          total={meta.total_count}
          pageSize={dataState.take}
          onFilterChange={filterChange}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState as any);
          }}
          skip={meta.page_size * meta.current_page - meta.page_size}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            sortChanged(e.sort);
          }}
        >
          <GridColumn
            field="exporter"
            sortable={false}
            filterable={false}
            title="Exporter"
          />
          <GridColumn
            field="state"
            sortable={false}
            filterable={false}
            title="State"
          />
          <GridColumn
            sortable={false}
            field="file_url"
            filterable={false}
            title="File"
            cell={(o) => (
              <td>
                {!!o.dataItem.file_url && (
                  <>
                    <p>
                      <a href={o.dataItem.file_url}>
                        {o.dataItem.file_url.split("/").pop()}
                      </a>
                    </p>
                  </>
                )}
              </td>
            )}
          />
          <GridColumn
            field="created_at"
            width="240"
            sortable={false}
            filterable={false}
            title="Created at"
          />
        </Grid>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
};
