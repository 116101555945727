import { Drawer } from "@mui/material";
import CommonLayout from "components/layouts/common-layout";
import { PackSizesTable } from "components/packSizes/packSizesTable";
import { PackSizesProvider } from "context/packSizesContext";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AddPackSize from "./addPackSize";
import EditPackSize from "./editPackSize";
import ViewPackSize from "./viewPackSize";
import { FormMode } from "models/util";

interface PackSizesProps {
  mode: FormMode;
}

const PackSizesPage = (props: PackSizesProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("PackSize");
  let { editId, previewId } = useParams();
  const [pack_size, setPackSize] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode);
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setPackSize(null);
      setMode(FormMode.Edit);
      getPackSizeById(editId).then((res: any) => {
        setPackSize(res.data.data);
      });
    }
  }, [editId]);

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getPackSizeById(previewId).then((res: any) => {
        setPackSize(res.data.data);
      });
    }
  }, [previewId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New PackSize");
    } else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit PackSize");
    } else {
      setDrawerTitle("PackSize");
    }
  }, [mode]);

  const getPackSizeById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/pack_sizes/${id}`);
  };

  const onExit = () => {
    setMode(FormMode.None);
    navigate("/pack-sizes", { replace: true });
  };

  return (
    <CommonLayout>
      <h2 className="float-start">PackSizes</h2>
      <button
        onClick={() => {
          navigate("/pack-sizes/new", { replace: true });
          setMode(FormMode.Create);
        }}
        className="btn btn-primary btn-block fw-bolder float-end"
      >
        <AddIcon />
        New PackSize
      </button>
      <PackSizesProvider>
        <div className="clearfix"></div>
        <PackSizesTable />
        <Drawer
          PaperProps={{ style: { width: "600px" } }}
          anchor={"right"}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" />
              <span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && <ViewPackSize pack_size={pack_size} />}
            {mode === FormMode.Create && <AddPackSize onExit={onExit} />}
            {mode === FormMode.Edit && pack_size && (
              <EditPackSize onExit={onExit} pack_size={pack_size} />
            )}
          </section>
        </Drawer>
      </PackSizesProvider>
    </CommonLayout>
  );
};

export default PackSizesPage;
