import { useState, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { gridSettings, initialSort } from "configs/gridSettings";
import useClassifications from "context/classificationsContext";
import { ActionColumns } from "components/grid/action-column";
import { FullPageLoader } from "components/shared/fullPageLoader";
import { initialDataState } from "configs/initialDataState";
import Toast from "components/toaster/toastr";
import ErrorToast from "components/toaster/errorToastr";
import { NavLink, useSearchParams } from "react-router-dom";
import { GuidCell } from "components/grid/GuidCell";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import SWDialog from "components/shared/dialog";
import { RangeFilterCell } from "components/grid/DateRangeFilterCell";
import { useSearchPagination } from "components/hooks/useSearchPagination";

export const ClassificationsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const {
    isLoading,
    classifications,
    meta,
    getClassifications,
    isSuccess,
    error,
    message,
    removeClassificationById,
  } = useClassifications();
  const [filter, setFilter] = useState<CompositeFilterDescriptor | any>(
    undefined,
  );
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [sort, setSort] = useState(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  useEffect(() => {
    const d = { ...dataState };
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(
      `?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`,
    );
    getClassifications({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  }, [dataState]);

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  };

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getClassifications({
        page: page,
        take: dataState.take,
        filters: filter?.filters,
        sort: sort,
      });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const removeClassification = () => {
    setIsDialogOpen(false);
    removeClassificationById(deleteId);
  };

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getClassifications({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  };

  return (
    <div>
      <SWDialog
        open={isDialogOpen}
        confirmText="Delete"
        text="Are you sure that you want to delete this Classification?"
        onConfirm={() => removeClassification()}
        onClose={() => setIsDialogOpen(false)}
      />
      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}
      {classifications && meta ? (
        <Grid
          {...gridSettings}
          filter={filter}
          onFilterChange={filterChange}
          data={classifications}
          className="k-grid-container"
          total={meta.total_count}
          pageSize={dataState.take}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState as any);
          }}
          skip={meta.page_size * meta.current_page - meta.page_size}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            sortChanged(e.sort);
          }}
        >
          <GridColumn
            field="id"
            title="UUID"
            sortable={false}
            width="110"
            cell={GuidCell}
          />
          <GridColumn
            field="name"
            title="Name"
            cell={(o) => (
              <td>
                <NavLink
                  to={`/classifications/${o.dataItem.id}`}
                  replace={true}
                >
                  {o.dataItem.name}
                </NavLink>
                <br />
              </td>
            )}
          />
          <GridColumn
            field="created_at"
            width="240"
            filterCell={RangeFilterCell}
            title="Created at"
          />
          <GridColumn
            field="updated_at"
            width="240"
            filterCell={RangeFilterCell}
            title="Updated at"
          />
          <GridColumn
            field="actions"
            sortable={false}
            title="Actions"
            filterable={false}
            cell={(o) => (
              <ActionColumns
                id={o.dataItem.id}
                route="classifications"
                onDelete={(id: string) => {
                  setDeleteId(id);
                  setIsDialogOpen(true);
                }}
              />
            )}
          />
        </Grid>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
};
