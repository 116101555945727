import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import usePackSizes from "context/packSizesContext";
import { SaveButton } from "components/shared/saveButton";

interface EditPackSizeProps {
  pack_size: {
    id: string;
    value: number;
    unit: string;
  };
  onExit: () => void;
}

const EditPackSize = ({ pack_size, onExit }: EditPackSizeProps) => {
  const { editPackSize, fetchUnits, units } = usePackSizes();

  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      value: pack_size?.value,
      unit: pack_size?.unit,
    },
  });

  const onSubmit = (data: any) => {
    data.id = pack_size.id;

    editPackSize(data).then((error: any) => {
      if (!error) {
        onExit();
      }
    });
  };

  return (
    <form
      className={
        errors.value || errors.unit ? "was-validated" : "needs-validation"
      }
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <label className="form-label">
                Value <span className="small">(optional)</span>
              </label>
              <input
                type="number"
                className={`form-control ${errors.value ? "is-invalid" : ""}`}
                {...register("value", {
                  valueAsNumber: true,
                  required: false,
                })}
              />
              {errors.value && (
                <div className="invalid-feedback">
                  Please provide a valid numeric value.
                </div>
              )}
            </div>

            <div className="col-6">
              <label className="form-label">Unit</label>
              <select
                className={`form-control ${errors.unit ? "is-invalid" : ""}`}
                {...register("unit", { required: true })}
              >
                <option value="">-- Select a unit --</option>
                {units?.map((unit: string) => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
              {errors.unit && (
                <div className="invalid-feedback">This field is required.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <SaveButton />
    </form>
  );
};

export default EditPackSize;
