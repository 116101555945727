import CommonLayout from "components/layouts/common-layout";
import { ExportsTable } from "components/exports/exportsTable";
import { ExportsProvider } from "context/exportsContext";

const ExportsPage = () => {
  return (
    <CommonLayout>
      <h2 className="float-start">Exports</h2>
      <ExportsProvider>
        <div className="clearfix"></div>
        <ExportsTable />
      </ExportsProvider>
    </CommonLayout>
  );
};

export default ExportsPage;
