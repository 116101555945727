import { Box, Modal, Popover } from "@mui/material";
import CommonLayout from "components/layouts/common-layout";
import { PredefinedClassesTable } from "components/predefinedClasses/predefinedClassesTable";
import { PredefinedClassesProvider } from "context/predefinedClassesContext";
import { FormMode } from "models/util";
import { Drawer } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreatePredefinedClass from "./createPredefinedClass";
import { LabelsProvider } from "context/labelsContext";
import AddIcon from "@mui/icons-material/Add";
import { DropZone } from "components/shared/dropZone";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import { BootstrapButton } from "components/shared/bootstrapButton";
import { modalStyle } from "components/shared/modalStyles";
import { useFileUpload } from "hooks/useFileUpload";
import { CategoriesProvider } from "context/categoriesContext";
import { RegionsProvider } from "context/regionsContext";

interface PredefinedClassesProps {
  mode: FormMode;
}

const PredefinedClassesPage = (props: PredefinedClassesProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Predefined Class");
  let { editId, previewId, csvid } = useParams();
  const [pc, setPc] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode);
  const navigate = useNavigate();
  const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState<boolean>(false);
  const [file, setFile] = useState<Blob>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadFile] = useFileUpload();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (editId) {
      setPc(null);
      setMode(FormMode.Edit);
      getPredefinedClassById(editId).then((res: any) => {
        setPc(res.data.data);
      });
    }
  }, [editId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Predefined Class");
    } else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Predefined Class");
    }
  }, [mode]);

  const getPredefinedClassById = (id: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/predefined_classes/${id}`,
    );
  };

  const onExit = () => {
    setMode(FormMode.None);
    setPc(null);
    navigate("/predefined-classes", { replace: true });
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handlePopClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const handleBulkRemoveProductsCsvOpen = () => {
    setIsProductModalOpen(true);
    handlePopClose();
  };

  const handleBulkRemoveLabelsCsvOpen = () => {
    setIsLabelModalOpen(true);
    handlePopClose();
  };

  const handleProductModalClose = () => {
    setFile(undefined);
    setIsProductModalOpen(false);
  };

  const handleLabelModalClose = () => {
    setFile(undefined);
    setIsLabelModalOpen(false);
  };

  const onFileAdded = (file: any) => {
    setFile(file);
  };

  const handleProductUpload = () => {
    setIsUploading(true);
    const options = {
      import_type: "remove_products_from_predefined_classes",
      import_strategies: [],
      context: {},
    };
    uploadFile(file, options).then(() => {
      setIsUploading(false);
    });
  };

  const handleLabelUpload = () => {
    setIsUploading(true);
    const options = {
      import_type: "remove_labels_from_predefined_classes",
      import_strategies: [],
      context: {},
    };
    uploadFile(file, options).then(() => {
      setIsUploading(false);
    });
  };

  return (
    <CommonLayout>
      <Modal
        open={isProductModalOpen}
        onClose={handleProductModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon
              onClick={handleProductModalClose}
              className="primary-col pointer"
              style={{ marginTop: "-4px" }}
            />
            <span style={{ marginLeft: "40px" }}>
              Remove products from predefined classes
            </span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon
                className="primary-col float-left"
                style={{ fontSize: 30 }}
              />
              <p className="m-0 float-left middle-par">
                Example of removing products from predefined classes
              </p>
              <a
                type="button"
                href={`${process.env.REACT_APP_API_BASE_URL}/imports/remove_products_from_predefined_classes.csv`}
                className="btn btn-sm btn-secondary float-right"
              >
                Download
              </a>
            </div>
          </div>
          {!isUploading && !!file && (
            <BootstrapButton variant="contained" onClick={handleProductUpload}>
              <FileUploadIcon fontSize="small" />
              <label>Upload</label>
            </BootstrapButton>
          )}
          {isUploading && (
            <BootstrapButton disabled variant="contained">
              <label>In progress...</label>
            </BootstrapButton>
          )}
        </Box>
      </Modal>

      <Modal
        open={isLabelModalOpen}
        onClose={handleLabelModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon
              onClick={handleLabelModalClose}
              className="primary-col pointer"
              style={{ marginTop: "-4px" }}
            />
            <span style={{ marginLeft: "40px" }}>
              Remove labels from predefined classes
            </span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon
                className="primary-col float-left"
                style={{ fontSize: 30 }}
              />
              <p className="m-0 float-left middle-par">
                Example of removing labels from predefined classes
              </p>
              <a
                type="button"
                href={`${process.env.REACT_APP_API_BASE_URL}/imports/remove_labels_from_predefined_classes.csv`}
                className="btn btn-sm btn-secondary float-right"
              >
                Download
              </a>
            </div>
          </div>
          {!isUploading && !!file && (
            <BootstrapButton variant="contained" onClick={handleLabelUpload}>
              <FileUploadIcon fontSize="small" />
              <label>Upload</label>
            </BootstrapButton>
          )}
          {isUploading && (
            <BootstrapButton disabled variant="contained">
              <label>In progress...</label>
            </BootstrapButton>
          )}
        </Box>
      </Modal>

      <h2 className="float-start">Predefined Classes</h2>
      <div className="float-end floating-button-rfight">
        <button
          onClick={() => {
            navigate("/predefined-classes/new", { replace: true });
            setMode(FormMode.Create);
          }}
          className="btn btn-primary btn-block fw-bolder float-end floating-button-right"
        >
          <AddIcon />
          New Predefined Class
        </button>
        <button
          aria-describedby={id}
          onClick={handlePopClick}
          className="btn btn-secondary btn-block fw-bolder float-end upload-btn"
        >
          <FileUploadIcon />
          Remove Labels by CSV
        </button>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <section className="csv-menu">
          <p>
            <span
              onClick={handleBulkRemoveProductsCsvOpen}
              className="nav-link pointer"
            >
              Remove Labels from Predefined Classes by Product GUID
            </span>
            <span
              onClick={handleBulkRemoveLabelsCsvOpen}
              className="nav-link pointer"
            >
              Remove Labels from Predefined Classes by Label UUID
            </span>
          </p>
        </section>
      </Popover>
      <PredefinedClassesProvider>
        <CategoriesProvider>
          <RegionsProvider>
            <LabelsProvider>
              <div className="clearfix"></div>
              <PredefinedClassesTable />
              <Drawer
                PaperProps={{
                  style: { width: mode === FormMode.View ? "1400px" : "600px" },
                }}
                anchor={"right"}
                open={mode == FormMode.Create || mode == FormMode.Edit}
                onClose={onExit}
              >
                <section className="sidebar-entity p-4">
                  <h2 className="float-start">
                    <ArrowBackIcon
                      onClick={onExit}
                      className="primary-col pointer"
                    />
                    <span className="px-2">{drawerTitle}</span>
                  </h2>
                  <div className="clearfix" />
                  {mode === FormMode.Edit && pc && (
                    <CreatePredefinedClass
                      mode={FormMode.Edit}
                      onExit={onExit}
                      pc={pc}
                    />
                  )}
                  {mode === FormMode.Create && (
                    <CreatePredefinedClass
                      mode={FormMode.Create}
                      onExit={onExit}
                    />
                  )}
                </section>
              </Drawer>
            </LabelsProvider>
          </RegionsProvider>
        </CategoriesProvider>
      </PredefinedClassesProvider>
    </CommonLayout>
  );
};

export default PredefinedClassesPage;
