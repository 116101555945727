import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { ImageUpload } from "./imageUpload";
import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SWDialog from "components/shared/dialog";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";

const BootstrapButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: "#196e8c",
  borderColor: "#0063cc",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#196e8c",
    borderColor: "#56b7d9",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#196e8c",
    borderColor: "#56b7d9",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem #196e8c",
  },
});

interface FileUploadProps {
  photo_ids: string[];
  onImageAdded: any;
  readOnly?: boolean;
  onImageDeleted?: any;
}

export default function FileUpload(props: FileUploadProps) {
  const [newImages, setNewImages] = useState<ImageUpload[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<number>();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleUpload = () => {
    inputRef.current?.click();
  };

  useEffect(() => {
    if (props.photo_ids && props.photo_ids.length) {
      const photos = props?.photo_ids?.map((p: any) => ({
        photo_url: p.url,
        id: p.id,
      }));
      setNewImages([...(photos as ImageUpload[])]);
    }
  }, [props.photo_ids]);

  const onFileChange = (e: React.ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    let file;
    if (target.files != null) {
      file = target!.files[0];
    }
    uploadFile(file);
  };

  const uploadFile = (photo: any) => {
    let formData = new FormData();
    formData.append("photo", photo);
    axios
      .post(`${process.env.REACT_APP_API_URL}/photos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setNewImages([...newImages, res.data.data]);
        props.onImageAdded(res.data.data.id);
      });
  };

  const onDelete = (id: number) => {
    setActiveId(id);
    setIsDialogOpen(true);
  };

  const deleteImage = () => {
    setIsDialogOpen(false);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/photos/${activeId}`)
      .then((res) => {
        setNewImages([...newImages.filter((i) => i.id !== activeId)]);
        props.onImageDeleted(activeId);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-center"></div>
      <SWDialog
        open={isDialogOpen}
        text="Are you sure that you want to delete this Photo?"
        confirmText="Delete"
        onConfirm={() => deleteImage()}
        onClose={() => setIsDialogOpen(false)}
      />
      <div className="row">
        {newImages.map((i) => (
          <div key={i.key} className="col-6 relative">
            <span className="image-container">
              <img
                className="w-100-fit pr-5"
                src={i.photo_url}
                alt={i.photo_url}
              />
            </span>
            {!props.readOnly && (
              <button
                type="button"
                onClick={() => onDelete(i.id)}
                className="btn btn-sm btn-secondary mb-5 mt-2"
              >
                <DeleteIcon fontSize="small" />
                Delete
              </button>
            )}
          </div>
        ))}
        {!newImages.length && (
          <div className="d-flex justify-content-center">
            <span className="color-grey grey-box">
              <PermMediaOutlinedIcon sx={{ fontSize: 120 }} />
            </span>
          </div>
        )}
      </div>
      {!props.readOnly && (
        <div className="d-flex justify-content-center">
          <Stack spacing={2} direction="row">
            <BootstrapButton variant="contained" onClick={handleUpload}>
              <FileUploadIcon fontSize="small" />
              <label>Upload</label>
            </BootstrapButton>
          </Stack>
          <input
            id="file-upload"
            ref={inputRef}
            hidden
            accept="image/*"
            type="file"
            onChange={onFileChange}
          />
        </div>
      )}
    </>
  );
}
