import { useState, useEffect } from "react";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { gridSettings } from "configs/gridSettings";
import useCustomAttributes from "context/customAttributesContext";
import { FullPageLoader } from "components/shared/fullPageLoader";
import { initialDataState } from "configs/initialDataState";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import ErrorToast from "components/toaster/errorToastr";
import Toast from "components/toaster/toastr";
import SWDialog from "components/shared/dialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";

export const CustomAttributesTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteAttributeId, setDeleteAttributeId] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    isSuccess,
    message,
    error,
    metaCustomAttributes,
    getCustomAttributes,
    removeCustomAttributeById,
    customAttributes,
  } = useCustomAttributes();
  let { catalogId } = useParams();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [sort, setSort] = useState([
    { field: "name", dir: "asc" },
  ] as Array<SortDescriptor>);

  useEffect(() => {
    const _pageNumber = parseInt(searchParams.get("pageNumber") || "1", 10);
    const _pageSize = parseInt(searchParams.get("pageSize") || "20", 10);
    dataState.take = _pageSize;
    dataState.skip = (_pageNumber - 1) * _pageSize;
    dataState.isInitial = true;
    setDataState(dataState);
    getCustomAttributes({
      page: _pageNumber,
      take: dataState.take,
      catalogId: catalogId,
    });
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(
      `?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`,
    );
    getCustomAttributes({
      page: page,
      take: dataState.take,
      catalogId: catalogId,
      filters: filter?.filters,
      sort: sort,
    });
  }, [dataState]);

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getCustomAttributes({
        page: page,
        take: dataState.take,
        filters: filter?.filters,
        sort: sort,
      });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const removeCustomAttribute = () => {
    setIsDialogOpen(false);
    removeCustomAttributeById(catalogId, deleteAttributeId);
  };
  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  };

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getCustomAttributes({
      page: page,
      take: dataState.take,
      catalogId: catalogId,
      filters: filter?.filters,
      sort: sort,
    });
  };

  return (
    <div>
      <>
        <SWDialog
          open={isDialogOpen}
          confirmText="Delete"
          text="Are you sure that you want to delete this custom attribute?"
          content="All values for this attribute will be deleted as well."
          onConfirm={() => removeCustomAttribute()}
          onClose={() => setIsDialogOpen(false)}
        />
        {isSuccess && <Toast message={message} />}
        {error && <ErrorToast message={error} />}
        {customAttributes && metaCustomAttributes ? (
          <Grid
            {...gridSettings}
            filter={filter}
            onFilterChange={filterChange}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
              sortChanged(e.sort);
            }}
            data={customAttributes}
            total={metaCustomAttributes.total_count}
            pageSize={dataState.take}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState as any);
            }}
            skip={
              metaCustomAttributes.page_size *
                metaCustomAttributes.current_page -
              metaCustomAttributes.page_size
            }
          >
            <GridColumn
              field="name"
              title="Name"
              sortable={false}
              filterable={false}
              cell={(o: GridCellProps) => <td>{o.dataItem.name}</td>}
            />
            <GridColumn
              field="type"
              title="Data Type"
              width="200px"
              sortable={false}
              filterable={false}
              cell={(o) => <td>{o.dataItem.data_type}</td>}
            />
            <GridColumn
              field="actions"
              title="Actions"
              sortable={false}
              filterable={false}
              cell={(o) => (
                <td>
                  <NavLink
                    to={`/customer-product-lists/custom-attributes/${catalogId}/edit/${o.dataItem.id}`}
                    state={{ customAttribute: o.dataItem }}
                    replace={true}
                  >
                    <button type="button" className="btn btn-sm btn-secondary">
                      <EditIcon fontSize="small" />
                      Edit
                    </button>
                    &nbsp;&nbsp;
                  </NavLink>
                  <button
                    type="button"
                    onClick={() => {
                      setIsDialogOpen(true);
                      setDeleteAttributeId(o.dataItem.id);
                    }}
                    className="btn btn-sm btn-secondary"
                  >
                    <DeleteIcon fontSize="small" />
                    Delete
                  </button>
                </td>
              )}
            />
          </Grid>
        ) : (
          <FullPageLoader />
        )}
      </>
    </div>
  );
};
