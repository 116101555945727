import React, { useEffect, useState } from "react";
import { Box, Modal, Popover } from "@mui/material";
import { useForm } from "react-hook-form";
import usePredefinedClasses from "context/predefinedClassesContext";
import LabelListView from "./labelListView";
import { Label } from "models/label";
import useLabels from "context/labelsContext";
import axios from "axios";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FormMode, Meta } from "models/util";
import { PredefinedClass } from "models/predefined_class";
import CommonLayout from "components/layouts/common-layout";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { convertArrayToQueryString } from "shared/functions/helpers";
import ErrorToast from "components/toaster/errorToastr";
import Toast from "components/toaster/toastr";
import { Checkbox } from "@mui/material";
import {
  DEBOUNCE_TIMEOUT,
  NOVALUE,
  TOASTR_TIMEOUT,
} from "shared/constants/constants";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import SWDialog from "components/shared/dialog";
import { useFileUpload } from "hooks/useFileUpload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { BootstrapButton } from "components/shared/bootstrapButton";
import { DropZone } from "components/shared/dropZone";
import { modalStyle } from "components/shared/modalStyles";

interface Props {
  mode?: FormMode;
  pc?: PredefinedClass;
}

const PAGE_SIZE: number = 100;

const EditPredefinedClass = (props: Props) => {
  let { previewId } = useParams();
  const { getLabels, labels, meta } = useLabels();
  const [assignedLabels, setAssignedLabels] = useState<Label[]>([]);
  const [filteredLabels, setFilteredLabels] = useState<Label[]>([]);
  const [allLabelsName, setAllLabelsName] = useState("");
  const [allLabelsTag, setAllLabelsTag] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [successMessage, setSuccessMessage] = useState<string | undefined>("");
  const {
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: props.pc?.id,
      name: props.pc?.name,
      description: props.pc?.description,
      labels: props.pc?.labels,
      filtered_name: "",
      filtered_all_name: "",
      filtered_all_tags: "",
    },
  });
  const [assignedSelection, setAssignedSelection] = useState<string[]>([]);
  const [assignedLabelName, setAssignedLabelName] = useState<string>("");
  const [allSelection, setAllSelection] = useState<string[]>([]);
  const [assignedSelectionConfirmed, setAssignedSelectionConfirmed] = useState<
    string[]
  >([]);
  const [allSelectionConfirmed, setAllSelectionConfirmed] = useState<string[]>(
    [],
  );
  const navigate = useNavigate();
  const [pc, setPc] = useState<any>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [assignedCurrentPage, setAssignedCurrentPage] = useState(1);
  const [assignedMeta, setAssignedMeta] = useState<Meta>({
    current_page: 1,
    total_count: 1,
    page_size: PAGE_SIZE,
  });
  const [dateRange, setDateRange] = useState({
    start: undefined,
    end: undefined,
  });
  const { error, resetPredefinedClass } = usePredefinedClasses();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const getLabelsForPredefinedClass = () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/predefined_classes/${previewId}/labels?page=${assignedMeta.current_page}&items_per_page=${PAGE_SIZE}`,
    );
  };

  const getAssignedLabels = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/predefined_classes/${previewId}/labels?page=${assignedCurrentPage}&items_per_page=${PAGE_SIZE}&name_like=${assignedLabelName}`,
      )
      .then((res) => {
        setAssignedLabels(res.data.data);
        setAssignedMeta(res.data.meta);
      });
  };
  useEffect(() => {
    const getData = setTimeout(() => {
      getAllLabels(1);
    }, DEBOUNCE_TIMEOUT);

    return () => clearTimeout(getData);
  }, [allLabelsName, allLabelsTag, dateRange]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getAllLabels();
    }, DEBOUNCE_TIMEOUT);

    return () => clearTimeout(getData);
  }, [currentPage]);

  useEffect(() => {
    const getData = setTimeout(() => {
      getAssignedLabels();
    }, DEBOUNCE_TIMEOUT);

    return () => clearTimeout(getData);
  }, [assignedCurrentPage, assignedLabelName]);

  const getAllLabels = (aCurrentPage = currentPage) => {
    getLabels({
      page: aCurrentPage,
      take: PAGE_SIZE,
      filters: [
        { field: "tag_name", value: allLabelsTag },
        { field: "name_like", value: allLabelsName },
        { field: "created_at", value: dateRange.start && dateRange },
      ],
    });
  };

  const getAllLabelsForName = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setAllLabelsName(newValue);
  };

  const getAllLabelsForTags = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setAllLabelsTag(newValue);
  };

  useEffect(() => {
    if (previewId) {
      setPc(null);
      getPredefinedClassById(previewId).then((res: any) => {
        setPc(res.data.data);
      });
      getPredefinedClassLabelsById(previewId).then((res: any) => {
        setAssignedLabels(res.data.data);
        setAssignedMeta(res.data.meta);
      });
    }
  }, [previewId]);

  useEffect(() => {
    setFilteredLabels(labels);
  }, [labels]);

  useEffect(() => {
    if (assignedSelectionConfirmed.length > 0) {
      unassignLabelsToPredefinedClass();
    }
  }, [assignedSelectionConfirmed]);

  useEffect(() => {
    if (allSelectionConfirmed.length > 0) {
      assignLabelsToPredefinedClass();
    }
  }, [allSelectionConfirmed]);

  useEffect(() => {
    if (meta?.current_page) {
      setCurrentPage(meta?.current_page);
    }
  }, [meta]);

  const assignLabelsToPredefinedClass = () => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/predefined_classes/${previewId}/labels`,
        { labels: allSelectionConfirmed },
      )
      .then((res) => {
        handleSuccess("Labels have been successfully assigned");
        getPredefinedClassById(previewId as string).then((res: any) => {
          setPc(res.data.data);
        });
        setAllSelection([]);
        setFilteredLabels([...filteredLabels]);
        getLabelsForPredefinedClass().then((res) => {
          setAssignedLabels(res.data.data);
        });
      })
      .catch((e) => {
        handleError(e);
      });
  };
  const unassignLabelsToPredefinedClass = () => {
    return axios
      .delete(
        `${process.env.REACT_APP_API_URL}/predefined_classes/${previewId}/labels?${convertArrayToQueryString(assignedSelectionConfirmed, "labels")}`,
      )
      .then(() => {
        handleSuccess("Labels have been successfully unassigned");
        getPredefinedClassById(previewId as string).then((res: any) => {
          setPc(res.data.data);
        });
        getLabelsForPredefinedClass().then((res) => {
          setAssignedLabels(res.data.data);
          setAssignedSelection([]);
        });
      })
      .catch((e) => handleError(e));
  };
  const labelAllSelected = (id: string) => {
    setAllSelection([...allSelection, id]);
  };
  const labelAllUnselected = (id: string) => {
    setAllSelection([...allSelection.filter((e) => e !== id)]);
  };
  const labelAssignedSelected = (id: string) => {
    setAssignedSelection([...assignedSelection, id]);
  };
  const labelAsignedUnSelected = (id: string) => {
    setAssignedSelection([...assignedSelection.filter((e) => e !== id)]);
  };
  const leftArrowClicked = () => {
    setAssignedSelectionConfirmed([...assignedSelection]);
  };
  const rightArrowClicked = () => {
    setAllSelectionConfirmed([...allSelection]);
  };
  const selectAllLabels = () => {
    if (allSelection.length !== filteredLabels.length) {
      setAllSelection([...filteredLabels.map((l) => l.id)]);
    } else {
      setAllSelection([]);
    }
  };
  const selectAllAssignedLabels = () => {
    if (assignedSelection.length !== assignedLabels.length) {
      setAssignedSelection([...assignedLabels.map((l) => l.id)]);
    } else {
      setAssignedSelection([]);
    }
  };
  const allLabelRightArrowClicked = () => {
    setCurrentPage(currentPage + 1);
  };

  const allLabelLeftArrowClicked = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const assignedLabelRightArrowClicked = () => {
    if (
      assignedMeta.total_count - assignedMeta!.current_page * PAGE_SIZE >=
      1
    ) {
      setAssignedCurrentPage(assignedCurrentPage + 1);
    }
  };

  const assignedLabelLeftArrowClicked = () => {
    if (assignedMeta.current_page > 1) {
      setAssignedCurrentPage(assignedCurrentPage - 1);
    }
  };

  const getPredefinedClassById = (id: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/predefined_classes/${id}`,
    );
  };
  const getPredefinedClassLabelsById = (id: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/predefined_classes/${id}/labels?items_per_page=${PAGE_SIZE}`,
    );
  };

  const handleDateRangeChange = (event: any) => {
    if (event?.length > 1) {
      setDateRange({
        start: event[0],
        end: event[1],
      });
    } else {
      setDateRange({
        start: undefined,
        end: undefined,
      });
    }
  };

  const handleError = (e: any) => {
    setErrorMessage(e?.response?.data.errors[0]?.message);
    setTimeout(() => {
      setErrorMessage("");
    }, TOASTR_TIMEOUT);
  };

  const handleSuccess = (message: string) => {
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage("");
    }, TOASTR_TIMEOUT);
  };

  const handleReset = () => {
    resetPredefinedClass(previewId).then(() => {
      if (!error) {
        getPredefinedClassById(previewId as string).then((res: any) => {
          setPc(res.data.data);
        });
        getLabelsForPredefinedClass().then((res) => {
          setAssignedLabels(res.data.data);
          setAssignedSelection([]);
        });
      }
    });
    setIsDialogOpen(false);
  };

  const [IsImportByLabelModalOpen, setIsImportByLabelModalOpen] =
    useState<boolean>(false);
  const [IsImportByProductModalOpen, setIsImportByProductModalOpen] =
    useState<boolean>(false);
  const [file, setFile] = useState<Blob>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [uploadFile] = useFileUpload();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const modalWidht = 750;

  const handlePopClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleImportByLabelOpen = () => {
    setIsImportByLabelModalOpen(true);
    handlePopClose();
  };
  const handleImportByProductOpen = () => {
    setIsImportByProductModalOpen(true);
    handlePopClose();
  };
  const handleClose = () => {
    setFile(undefined);
    setIsImportByLabelModalOpen(false);
    setIsImportByProductModalOpen(false);
  };

  const onFileAdded = (file: any) => {
    setFile(file);
  };

  const handleUpload = (import_type: string) => {
    setIsUploading(true);
    const options = {
      import_type: import_type,
      import_strategies: [],
      context: { predefined_class_id: previewId },
    };
    uploadFile(file, options).then(() => {
      setIsUploading(false);
    });
  };

  return (
    <CommonLayout>
      {/* Add labels by label uuid Modal */}
      <Modal
        open={IsImportByLabelModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...modalStyle, width: modalWidht }}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon
              onClick={handleClose}
              className="primary-col pointer"
              style={{ marginTop: "-4px" }}
            />
            <span style={{ marginLeft: "40px" }}>
              Add labels to predefined class by label uuid via CSV
            </span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon
                className="primary-col float-left"
                style={{ fontSize: 30 }}
              />
              <p className="m-0 float-left middle-par">
                Example of adding labels to predefined class by label uuid
              </p>
              <a
                type="button"
                href={`${process.env.REACT_APP_API_BASE_URL}/imports/predefined_class_labels.csv`}
                className="btn btn-sm btn-secondary float-right"
              >
                Download
              </a>
            </div>
          </div>
          {!isUploading && !!file && (
            <>
              <BootstrapButton
                variant="contained"
                onClick={() => handleUpload("predefined_class_labels")}
              >
                <FileUploadIcon fontSize="small" />
                <label>Upload</label>
              </BootstrapButton>
            </>
          )}
          {isUploading && (
            <>
              <BootstrapButton
                disabled
                variant="contained"
                onClick={() => handleUpload("predefined_class_labels")}
              >
                <label>In progress...</label>
              </BootstrapButton>
            </>
          )}
        </Box>
      </Modal>
      {/* Add labels by product uuid Modal */}
      <Modal
        open={IsImportByProductModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...modalStyle, width: modalWidht }}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon
              onClick={handleClose}
              className="primary-col pointer"
              style={{ marginTop: "-4px" }}
            />
            <span style={{ marginLeft: "40px" }}>
              Add labels to predefined class by product uuid via CSV
            </span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon
                className="primary-col float-left"
                style={{ fontSize: 30 }}
              />
              <p className="m-0 float-left middle-par">
                Example of adding labels to predefined class by product uuid
              </p>
              <a
                type="button"
                href={`${process.env.REACT_APP_API_BASE_URL}/imports/predefined_class_products.csv`}
                className="btn btn-sm btn-secondary float-right"
              >
                Download
              </a>
            </div>
          </div>
          {!isUploading && !!file && (
            <>
              <BootstrapButton
                variant="contained"
                onClick={() => handleUpload("predefined_class_products")}
              >
                <FileUploadIcon fontSize="small" />
                <label>Upload</label>
              </BootstrapButton>
            </>
          )}
          {isUploading && (
            <>
              <BootstrapButton
                disabled
                variant="contained"
                onClick={() => handleUpload("predefined_class_products")}
              >
                <label>In progress...</label>
              </BootstrapButton>
            </>
          )}
        </Box>
      </Modal>

      <SWDialog
        open={isDialogOpen}
        confirmText="Remove all labels"
        text="Are you sure that you want to unassign all labels?"
        onConfirm={() => handleReset()}
        onClose={() => setIsDialogOpen(false)}
      />
      {errorMessage && <ErrorToast message={errorMessage} />}
      {error && <ErrorToast message={error} />}
      {successMessage && <Toast message={successMessage} />}
      <h2 className="float-start">
        {previewId && (
          <ArrowBackIcon
            onClick={() => {
              navigate("/predefined-classes", { replace: true });
            }}
            className="primary-col pointer"
          />
        )}

        {pc && previewId ? (
          <>
            <span className="mx-3">
              <span className="light-gray small">Predefined Classes</span>
              <span className="larger">{pc?.name}</span>
            </span>
            <EditIcon
              fontSize="small"
              className="primary-col pointer edit-custom-icon"
              onClick={() => {
                navigate("/predefined-classes/edit/" + previewId, {
                  replace: true,
                });
              }}
            />
          </>
        ) : (
          <>loading...</>
        )}
      </h2>
      <div className="float-end floating-button-rfight">
        <button
          type="button"
          onClick={(e) => {
            setIsDialogOpen(true);
          }}
          className="btn btn-outline-danger fw-bolder my-1 mx-3"
        >
          <DeleteIcon />
          Remove all Labels
        </button>
        <button
          aria-describedby={id}
          onClick={handlePopClick}
          className="btn btn-secondary fw-bolder"
        >
          <UploadIcon />
          Upload CSV
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <section className="csv-menu">
            <p>
              <span
                onClick={handleImportByLabelOpen}
                className="nav-link pointer"
              >
                Add labels by label uuid
              </span>
              <span
                onClick={handleImportByProductOpen}
                className="nav-link pointer"
              >
                Add labels by product uuid
              </span>
            </p>
          </section>
        </Popover>
      </div>
      <div className="clearfix"></div>
      <div className="row">
        <div className="card mb-4">
          <div className="card-body">
            <label className="form-label lighter-gray">Description</label>
            {pc?.description || NOVALUE}
          </div>
        </div>

        <div className="card-body col-47">
          <div className="card pb-3 pr-2">
            <div className="card-body pr-2 ml-2 pr-2">
              <LabelListView
                labelList={filteredLabels}
                selectedLabels={allSelection}
                onSelected={labelAllSelected}
                onUnselected={labelAllUnselected}
              >
                <div>
                  <div className="sw-magnifying-glass">
                    <input
                      className="form-control pl-3 form-height"
                      {...register("filtered_all_name")}
                      onChange={getAllLabelsForName}
                      placeholder="Search by name"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <div style={{ width: "100%" }}>
                      <DateRangePicker
                        placement="bottomStart"
                        placeholder="From - To"
                        character=" - "
                        format="dd-MM-yyyy"
                        onChange={handleDateRangeChange}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="relative">
                      <LocalOfferIcon
                        className="tags-icon lighter-gray"
                        fontSize="small"
                        style={{ marginTop: "3px" }}
                      />
                      <input
                        className="form-control pl-3 form-height"
                        {...register("filtered_all_tags")}
                        onChange={getAllLabelsForTags}
                        placeholder="Search by tags"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="float-start pt-2">
                      <Checkbox
                        onChange={(e) => {
                          selectAllLabels();
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />{" "}
                      {allSelection.length} selected{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="float-end pt-3">
                      {meta?.total_count} labels
                    </span>
                  </div>
                </div>
              </LabelListView>
            </div>
            <div className="list-view-pagination">
              <span
                className={
                  "label-chevron-left bordered-block label-button-small relative right-absolute" +
                  (meta!.total_count - meta!.current_page * PAGE_SIZE < 1
                    ? " disabled-arrow"
                    : "")
                }
                onClick={allLabelRightArrowClicked}
              >
                <ArrowForwardIosIcon fontSize="small" />
              </span>
              <span
                className={
                  "label-chevron-right bordered-block label-button-small relative" +
                  (meta!.current_page === 1 ? " disabled-arrow" : "")
                }
                onClick={allLabelLeftArrowClicked}
              >
                <ArrowBackIosIcon fontSize="small" />
              </span>
            </div>
          </div>
        </div>
        <div className="card-body col-5p list-view-arrows-container">
          <span
            className="label-chevron-right bordered-block label-button relative"
            onClick={leftArrowClicked}
          >
            <ArrowBackIosIcon />
          </span>
          <span
            className="label-chevron-left bordered-block label-button relative right-absolute"
            onClick={rightArrowClicked}
          >
            <ArrowForwardIosIcon />
          </span>
        </div>
        <div className="card col-47">
          <div className="row">
            <div className="card-body pr-2 ml-2 pr-2">
              <LabelListView
                labelList={assignedLabels}
                selectedLabels={assignedSelection}
                onSelected={labelAssignedSelected}
                onUnselected={labelAsignedUnSelected}
              >
                <div>
                  <div className="sw-magnifying-glass">
                    <input
                      className="form-control pl-3 form-height"
                      {...register("filtered_name")}
                      onChange={(e) =>
                        setAssignedLabelName(e.currentTarget.value)
                      }
                      placeholder="Search by name"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <span className="float-start pt-2">
                      <Checkbox
                        onChange={(e) => {
                          selectAllAssignedLabels();
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                      />{" "}
                      {assignedSelection.length} selected{" "}
                    </span>
                  </div>
                  <div className="col-6">
                    <span className="float-end pt-2">
                      {pc?.labels_count}{" "}
                      {pc?.labels_count === 1 ? "label" : "labels"}
                    </span>
                  </div>
                </div>
              </LabelListView>
            </div>
            <div className="list-view-pagination">
              <span
                className={
                  "label-chevron-left bordered-block label-button-small relative right-absolute" +
                  (assignedMeta.total_count -
                    assignedMeta!.current_page * PAGE_SIZE <
                  1
                    ? " disabled-arrow"
                    : "")
                }
                onClick={assignedLabelRightArrowClicked}
              >
                <ArrowForwardIosIcon fontSize="small" />
              </span>
              <span
                className={
                  "label-chevron-right bordered-block label-button-small relative" +
                  (assignedMeta!.current_page === 1 ? " disabled-arrow" : "")
                }
                onClick={assignedLabelLeftArrowClicked}
              >
                <ArrowBackIosIcon fontSize="small" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default EditPredefinedClass;
