import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

const ViewPackType = (props: any) => {
  const { packType } = props;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <p className="mb-0">{packType?.name}</p>
        </div>
      </div>
      <NavLink to={`/pack-types/edit/${packType?.id}`} replace={true}>
        <button
          type="button"
          className="save-ui btn btn-primary btn-block fw-bolder float-end"
        >
          <EditIcon fontSize="small" />
          Edit
        </button>
      </NavLink>
    </>
  );
};

export default ViewPackType;
