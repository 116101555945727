import { Size } from "models/size";
import { Meta } from "models/util";

export interface SizesState {
  isLoading: boolean;
  sizes: Size[];
  units: string[];
  meta?: Meta;
  error?: string;
  getSizes?: any;
  editSize?: any;
  createSize?: any;
  isSuccess?: boolean;
  message?: string;
  clearActionSuccess?: any;
  removeSizeById?: any;
  fetchSizes?: any;
}

export const initialState: SizesState = {
  isLoading: true,
  sizes: [],
  units: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: "request" }
  | { type: "success"; results: { sizes: Size[]; meta: Meta } }
  | { type: "successUnits"; results: { units: string[] } }
  | { type: "failure"; error: string }
  | { type: "failure"; error?: string }
  | { type: "actionSuccess"; message: string }
  | { type: "clearActionSuccess" }
  | { type: "clearActionFailure" };

export function sizesReducer(state: SizesState, action: Action): SizesState {
  switch (action.type) {
    case "request":
      return { ...state, isLoading: true, error: undefined };
    case "success":
      return {
        ...state,
        isLoading: false,
        sizes: action.results.sizes,
        meta: action.results.meta,
      };
    case "successUnits":
      return { ...state, isLoading: false, units: action.results.units };
    case "failure":
      return { ...state, isLoading: false, error: action.error };
    case "actionSuccess":
      return {
        ...state,
        isSuccess: true,
        message: action.message,
        error: undefined,
      };
    case "clearActionSuccess":
      return { ...state, isSuccess: false, message: undefined };
    case "clearActionFailure":
      return { ...state, error: undefined };
  }
}
