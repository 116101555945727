export function convertJSONToArray(jsonObj: any) {
  var resultArray = [];
  for (var key in jsonObj) {
    if (jsonObj.hasOwnProperty(key)) {
      var obj = {} as any;
      obj[key] = jsonObj[key];
      resultArray.push(obj);
    }
  }
  return resultArray;
}

export function convertArrayToJSON(array: any[]) {
  var resultObject = {} as any;
  for (var i = 0; i < array.length; i++) {
    var obj = array[i];
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        resultObject[key] = obj[key];
      }
    }
  }
  return resultObject;
}

export function convertArrayToQueryString(arr: any[], queryStringName: string) {
  return arr.map((o) => `&${queryStringName}[]=${o}`).join("");
}

export function getProductOrInstance(
  instanceProp: string,
  productProp: string,
) {
  if (!!instanceProp || typeof instanceProp === "string") {
    return instanceProp;
  }
  return productProp;
}
