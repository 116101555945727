import { GridCellProps } from "@progress/kendo-react-grid";
const uuid = require("uuid");

export const StringArrCell = (props: GridCellProps) => {
  return (
    <td key={uuid.v4()}>
      {props.dataItem?.tag_list?.map((t: string, index: number) => (
        <>
          {index > 0 && ","} <span key={uuid.v4()}> {t}</span>
        </>
      ))}
    </td>
  );
};
