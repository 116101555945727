import { Product } from "models/product";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface ProductCatalogProductsState {
  isLoading: boolean;
  productCatalogProducts?: Product[];
  meta?: Meta;
  metaProductCatalogProducts: Meta;
  error?: string;
  attrErrors?: string[];
  getProductCatalogProducts?: any;
  createProductCatalogProduct?: any;
  editProductCatalogProduct?: any;
  editAttributeValue?: any;
  createAttributeValue?: any;
  isSuccess?: boolean;
  message?: string;
  clearActionSuccess?: any;
  removeProductCatalogProductById?: any;
  resetProducts?: any;
}

export const initialState: ProductCatalogProductsState = {
  isLoading: true,
  productCatalogProducts: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
  metaProductCatalogProducts: {
    current_page: 1,
    page_size: 20,
    total_count: 20,
  },
};

const addAttrErrors = (
  state: ProductCatalogProductsState,
  newAttrErrors: string[],
) => {
  if (state.attrErrors && state.attrErrors.length > 0) {
    return [...state.attrErrors, ...newAttrErrors];
  }
  return newAttrErrors;
};

type Action =
  | { type: "request" }
  | {
      type: "success";
      results: {
        productCatalogProducts: Product[];
        meta?: Meta;
        metaProductCatalogProducts: Meta;
      };
    }
  | { type: "failure"; error?: string }
  | { type: "actionSuccess"; message: string }
  | { type: "clearActionSuccess" }
  | { type: "clearActionFailure" }
  | { type: "resetProducts" }
  | { type: "attributeErrors"; attrErrors: string[] };

export function productCatalogProductReducer(
  state: ProductCatalogProductsState,
  action: Action,
): ProductCatalogProductsState {
  switch (action.type) {
    case "request":
      return {
        ...state,
        productCatalogProducts: [],
        isLoading: true,
        error: undefined,
      };
    case "success":
      return {
        ...state,
        isLoading: false,
        productCatalogProducts: action.results.productCatalogProducts,
        meta: action.results.meta,
        metaProductCatalogProducts: action.results.metaProductCatalogProducts,
      };
    case "failure":
      return {
        ...state,
        isLoading: false,
        error: action.error || DEFAULT_ERROR,
      };
    case "actionSuccess":
      return {
        ...state,
        isSuccess: true,
        message: action.message,
        error: undefined,
      };
    case "clearActionSuccess":
      return {
        ...state,
        isSuccess: false,
        message: undefined,
        attrErrors: undefined,
      };
    case "clearActionFailure":
      return { ...state, error: undefined };
    case "attributeErrors":
      return { ...state, attrErrors: addAttrErrors(state, action.attrErrors) };
    case "resetProducts":
      return { ...state, productCatalogProducts: [], error: undefined };
  }
}
