import CommonLayout from "components/layouts/common-layout";
import { ProductPackshotsIngestion } from "components/productPackshotsIngestion/productPackshotsIngestions";
import { ProductProvider } from "context/productContext";

interface ProductPackshotsIngestionProps {}

const ProductPackshotsIngestionPage = (
  props: ProductPackshotsIngestionProps,
) => {
  return (
    <CommonLayout>
      <ProductProvider>
        <ProductPackshotsIngestion />
      </ProductProvider>
    </CommonLayout>
  );
};

export default ProductPackshotsIngestionPage;
