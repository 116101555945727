import axios from "axios";
import { PackSize } from "models/pack_size";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { packSizesReducer, initialState } from "reducers/packSizesReducer";
import {
  clearActionFailure,
  clearActionSuccess,
  convertFiltersToQueryString,
  Meta,
  setFailure,
  setLoading,
  generateSortQueryParam,
  GetEntitiesProps,
} from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";

const PackSizesContext = createContext({
  ...initialState,
  fetchUnits: async () => {},
});

interface Props {
  children?: ReactNode;
}

export const PackSizesProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(packSizesReducer, initialState);

  const removePackSizeById = (id: string | undefined) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/pack_sizes/${id}`)
      .then((res) => {
        dispatch({
          type: "actionSuccess",
          message: "Pack size has been deleted!",
        });
        setTimeout(() => getPackSizes({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      });
  };

  const getPackSizes = ({
    page = 1,
    take = 20,
    filters = [],
    sort = [],
  }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/pack_sizes/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`,
      )
      .then(
        (res) => {
          setPackSizes(processDateObjects(res.data.data), res.data.meta);
        },
        () => setFailure(dispatch, "Error getting pack sizes"),
      );
  };

  const fetchUnits = async () => {
    setLoading(dispatch);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/pack_sizes/units`,
      );
      console.log("api response", res);
      const uniqueUnits = res.data.data.units || [];
      dispatch({ type: "successUnits", results: { units: uniqueUnits } });
      clearActionSuccess(dispatch);
    } catch (error) {
      setFailure(dispatch, "Error fetching unique units.");
      setTimeout(() => clearActionFailure(dispatch), 5000);
    }
  };

  const setPackSizes = (packSizes: PackSize[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { packSizes: packSizes, meta },
    });
  };

  const createPackSize = (c: PackSize) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/pack_sizes`, c).then(
      (res) => {
        dispatch({
          type: "actionSuccess",
          message: "Pack size has been created!",
        });
        setTimeout(() => {
          getPackSizes({ page: 1, take: 20 });
        }, 1000);
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      },
      (error) => {
        error = error.response.data;
        if (error && error.errors && error.errors[0].message) {
          dispatch({ type: "failure", error: error.errors[0].message });
        } else {
          dispatch({ type: "failure" });
        }
        setTimeout(() => clearActionFailure(dispatch), 5000);
        return error;
      },
    );
  };

  const editPackSize = (c: PackSize) => {
    return axios
      .put(`${process.env.REACT_APP_API_URL}/pack_sizes/${c.id}`, c)
      .then(
        (res) => {
          dispatch({
            type: "actionSuccess",
            message: "Pack size has been updated!",
          });
          setTimeout(() => getPackSizes({ page: 1, take: 20 }), 2000);
          setTimeout(() => clearActionSuccess(dispatch), 5000);
        },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message });
          } else {
            dispatch({ type: "failure" });
          }
          setTimeout(() => clearActionFailure(dispatch), 5000);
          return error;
        },
      );
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    packSizes: state.packSizes,
    units: state.units,
    meta: state.meta,
    message: state.message,
    isSuccess: state.isSuccess,
    setPackSizes: setPackSizes,
    getPackSizes: getPackSizes,
    editPackSize: editPackSize,
    createPackSize: createPackSize,
    removePackSizeById: removePackSizeById,
    fetchUnits: fetchUnits,
    setLoading,
    setFailure,
  };
  return (
    <PackSizesContext.Provider value={value}>
      {children}
    </PackSizesContext.Provider>
  );
};

const usePackSizes = () => {
  const context = useContext(PackSizesContext);

  if (context === undefined) {
    throw new Error("usePackSizes must be used within PackSizesContext");
  }

  return context;
};

export default usePackSizes;
