import { Alert, Snackbar } from "@mui/material";

const Toast = (props: any) => (
  <Snackbar
    open={true}
    autoHideDuration={6000}
    anchorOrigin={{ vertical: "top", horizontal: "center" }}
  >
    <Alert severity="success" sx={{ width: "100%" }}>
      {props.message}
    </Alert>
  </Snackbar>
);

export default Toast;
