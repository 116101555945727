import axios from "axios";
import { Size } from "models/size";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { sizesReducer, initialState } from "reducers/sizesReducer";
import {
  clearActionFailure,
  clearActionSuccess,
  convertFiltersToQueryString,
  Meta,
  setFailure,
  setLoading,
  generateSortQueryParam,
  GetEntitiesProps,
} from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";

const SizesContext = createContext({
  ...initialState,
  fetchUnits: async () => {},
});

interface Props {
  children?: ReactNode;
}

export const SizesProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(sizesReducer, initialState);

  const removeSizeById = (id: string | undefined) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/sizes/${id}`)
      .then((res) => {
        dispatch({ type: "actionSuccess", message: "Size has been deleted!" });
        setTimeout(() => getSizes({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      });
  };

  const getSizes = ({
    page = 1,
    take = 20,
    filters = [],
    sort = [],
  }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/sizes/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`,
      )
      .then(
        (res) => {
          setSizes(processDateObjects(res.data.data), res.data.meta);
        },
        () => setFailure(dispatch, "Error getting sizes"),
      );
  };

  const fetchUnits = async () => {
    setLoading(dispatch);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/sizes/units`,
      );
      console.log("api response", res);
      const uniqueUnits = res.data.data.units || [];
      dispatch({ type: "successUnits", results: { units: uniqueUnits } });
      clearActionSuccess(dispatch);
    } catch (error) {
      setFailure(dispatch, "Error fetching unique units.");
      setTimeout(() => clearActionFailure(dispatch), 5000);
    }
  };

  const setSizes = (sizes: Size[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { sizes: sizes, meta },
    });
  };

  const createSize = (c: Size) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/sizes`, c).then(
      (res) => {
        dispatch({ type: "actionSuccess", message: "Size has been created!" });
        setTimeout(() => {
          getSizes({ page: 1, take: 20 });
        }, 1000);
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      },
      (error) => {
        error = error.response.data;
        if (error && error.errors && error.errors[0].message) {
          dispatch({ type: "failure", error: error.errors[0].message });
        } else {
          dispatch({ type: "failure" });
        }
        setTimeout(() => clearActionFailure(dispatch), 5000);
        return error;
      },
    );
  };

  const editSize = (c: Size) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/sizes/${c.id}`, c).then(
      (res) => {
        dispatch({ type: "actionSuccess", message: "Size has been updated!" });
        setTimeout(() => getSizes({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(dispatch), 5000);
      },
      (error) => {
        error = error.response.data;
        if (error && error.errors && error.errors[0].message) {
          dispatch({ type: "failure", error: error.errors[0].message });
        } else {
          dispatch({ type: "failure" });
        }
        setTimeout(() => clearActionFailure(dispatch), 5000);
        return error;
      },
    );
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    sizes: state.sizes,
    units: state.units,
    meta: state.meta,
    message: state.message,
    isSuccess: state.isSuccess,
    setSizes: setSizes,
    getSizes: getSizes,
    editSize: editSize,
    createSize: createSize,
    removeSizeById: removeSizeById,
    fetchUnits: fetchUnits,
    setLoading,
    setFailure,
  };
  return (
    <SizesContext.Provider value={value}>{children}</SizesContext.Provider>
  );
};

const useSizes = () => {
  const context = useContext(SizesContext);

  if (context === undefined) {
    throw new Error("useSizes must be used within SizesContext");
  }

  return context;
};

export default useSizes;
