import { useState, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { gridSettings } from "configs/gridSettings";
import useModelSubscriptions from "context/modelSubscriptionsContext"; // Zaktualizowane do kontekstu subskrypcji modeli
import { FullPageLoader } from "components/shared/fullPageLoader";
import { initialDataState } from "configs/initialDataState";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import ErrorToast from "components/toaster/errorToastr";
import Toast from "components/toaster/toastr";
import SWDialog from "components/shared/dialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { NameValueCell } from "components/grid/NameValueCell";

export const ModelSubscriptionsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deleteSubscriptionId, setDeleteSubscriptionId] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    isSuccess,
    message,
    error,
    metaModelSubscriptions,
    getModelSubscriptions,
    removeModelSubscriptionById,
    modelSubscriptions,
  } = useModelSubscriptions();
  let { catalogId } = useParams();
  const [filter, setFilter] = useState<CompositeFilterDescriptor>();
  const [sort, setSort] = useState([
    { field: "createdAt", dir: "asc" },
  ] as Array<SortDescriptor>);

  useEffect(() => {
    const _pageNumber = parseInt(searchParams.get("pageNumber") || "1", 10);
    const _pageSize = parseInt(searchParams.get("pageSize") || "20", 10);
    dataState.take = _pageSize;
    dataState.skip = (_pageNumber - 1) * _pageSize;
    dataState.isInitial = true;
    setDataState(dataState);
    getModelSubscriptions({
      page: _pageNumber,
      take: dataState.take,
      catalogId: catalogId,
    });
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(
      `?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`,
    );
    getModelSubscriptions({
      page: page,
      take: dataState.take,
      catalogId: catalogId,
      filters: filter?.filters,
      sort: sort,
    });
  }, [dataState]);

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getModelSubscriptions({
        page: page,
        take: dataState.take,
        filters: filter?.filters,
        sort: sort,
      });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const removeModelSubscription = () => {
    setIsDialogOpen(false);
    removeModelSubscriptionById(catalogId, deleteSubscriptionId);
  };

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  };

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getModelSubscriptions({
      page: page,
      take: dataState.take,
      catalogId: catalogId,
      filters: filter?.filters,
      sort: sort,
    });
  };

  return (
    <div>
      <>
        <SWDialog
          open={isDialogOpen}
          confirmText="Delete"
          text="Are you sure that you want to delete this model subscription?"
          content="All data related to this subscription will be deleted."
          onConfirm={() => removeModelSubscription()}
          onClose={() => setIsDialogOpen(false)}
        />
        {isSuccess && <Toast message={message} />}
        {error && <ErrorToast message={error} />}
        {modelSubscriptions && metaModelSubscriptions ? (
          <Grid
            {...gridSettings}
            filter={filter}
            onFilterChange={filterChange}
            className="k-grid-container"
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
              sortChanged(e.sort);
            }}
            data={modelSubscriptions}
            total={metaModelSubscriptions.total_count}
            pageSize={dataState.take}
            onDataStateChange={(e: GridDataStateChangeEvent) => {
              setDataState(e.dataState as any);
            }}
            skip={
              metaModelSubscriptions.page_size *
                metaModelSubscriptions.current_page -
              metaModelSubscriptions.page_size
            }
          >
            <GridColumn
              field="model_list"
              width="600"
              title="Model List"
              cell={NameValueCell}
            />
            <GridColumn
              field="active"
              title="Active"
              width="100px"
              sortable={false}
              filterable={false}
              cell={(o) => <td>{o.dataItem.active ? "Yes" : "No"}</td>}
            />
            <GridColumn field="created_at" width="240" title="Created at" />
            <GridColumn
              field="actions"
              title="Actions"
              sortable={false}
              filterable={false}
              cell={(o) => (
                <td>
                  <NavLink
                    to={`/customer-product-lists/model-subscriptions/${catalogId}/edit/${o.dataItem.id}`}
                    state={{ modelSubscription: o.dataItem }}
                    replace={true}
                  >
                    <button type="button" className="btn btn-sm btn-secondary">
                      <EditIcon fontSize="small" />
                      Edit
                    </button>
                    &nbsp;&nbsp;
                  </NavLink>
                  <button
                    type="button"
                    onClick={() => {
                      setIsDialogOpen(true);
                      setDeleteSubscriptionId(o.dataItem.id);
                    }}
                    className="btn btn-sm btn-secondary"
                  >
                    <DeleteIcon fontSize="small" />
                    Delete
                  </button>
                </td>
              )}
            />
          </Grid>
        ) : (
          <FullPageLoader />
        )}
      </>
    </div>
  );
};
