import axios from "axios";
import { Product } from "models/product";
import { createContext, useReducer, useContext, ReactNode } from "react";
import {
  modelListProductsReducer,
  initialState,
} from "reducers/modelListProductsReducer";
import { processDateObjects } from "shared/functions/processDateObjects";
import {
  generateSortQueryParam,
  convertFiltersToQueryString,
  GetEntitiesProps,
} from "models/util";

const ModelListProductsContext = createContext(initialState);

interface Props {
  children?: ReactNode;
}

interface ModelListProductsIndexProps extends GetEntitiesProps {
  modelListId: string;
}

export const ModelListProductsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(modelListProductsReducer, initialState);

  const getModelListProducts = ({
    page = 1,
    take = 20,
    filters = [],
    sort = [],
    modelListId,
  }: ModelListProductsIndexProps) => {
    const f = convertFiltersToQueryString(filters);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/model_lists/${modelListId}/products?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`,
      )
      .then(
        (res) => {
          setProducts(processDateObjects(res?.data?.data), res?.data?.meta);
        },
        () =>
          dispatch({
            type: "failure",
            error: "Error getting Model List Products",
          }),
      );
  };

  const setProducts = (products: Product[], meta?: any) => {
    dispatch({
      type: "success",
      results: {
        products,
        meta,
      },
    });
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    products: state.products,
    meta: state.meta,
    getModelListProducts: getModelListProducts,
  };
  return (
    <ModelListProductsContext.Provider value={value}>
      {children}
    </ModelListProductsContext.Provider>
  );
};

const useModelListProducts = () => {
  const context = useContext(ModelListProductsContext);

  if (context === undefined) {
    throw new Error(
      "useModelListProducts must be used within a ModelListProductsProvider",
    );
  }

  return context;
};

export default useModelListProducts;
