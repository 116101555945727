import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import useSizes from "context/sizesContext";
import { SaveButton } from "components/shared/saveButton";

interface EditSizeProps {
  size: {
    id: string;
    value: string;
    unit: string;
  };
  onExit: () => void;
}

const EditSize = ({ size, onExit }: EditSizeProps) => {
  const { editSize, fetchUnits, units } = useSizes();

  useEffect(() => {
    fetchUnits();
  }, [fetchUnits]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      value: size?.value,
      unit: size?.unit,
    },
  });

  const onSubmit = (data: any) => {
    data.id = size.id;

    editSize(data).then((error: any) => {
      if (!error) {
        onExit();
      }
    });
  };

  return (
    <form
      className={
        errors.value || errors.unit ? "was-validated" : "needs-validation"
      }
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-6">
              <label className="form-label">
                Value <span className="small"></span>{" "}
              </label>
              <input
                type="number"
                className={`form-control ${errors.value ? "is-invalid" : ""}`}
                {...register("value", {
                  valueAsNumber: true,
                  required: true,
                })}
              />
              {errors.value && (
                <div className="invalid-feedback">
                  "Must be a float (e.g., 1.00, 2.30, 77.5)"
                </div>
              )}
            </div>

            <div className="col-6">
              <label className="form-label">Unit</label>
              <select
                className={`form-control ${errors.unit ? "is-invalid" : ""}`}
                {...register("unit", { required: true })}
              >
                <option value="">-- Select a unit --</option>
                {units?.map((unit: string) => (
                  <option key={unit} value={unit}>
                    {unit}
                  </option>
                ))}
              </select>
              {errors.unit && (
                <div className="invalid-feedback">This field is required.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <SaveButton />
    </form>
  );
};

export default EditSize;
