import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";

export const RangeFilterCell = (props: any) => {
  const handleChange = (event: any) => {
    if (event?.length > 1) {
      props.onChange({
        value: { start: event[0], end: event[1] },
        syntheticEvent: event.syntheticEvent,
      });
    } else {
      props.onChange({
        value: undefined,
        syntheticEvent: undefined,
      });
    }
  };

  return (
    <div style={{ width: "400px" }}>
      <DateRangePicker
        placement="bottomEnd"
        placeholder="From - To"
        character=" - "
        format="dd-MM-yyyy"
        onChange={handleChange}
      />
    </div>
  );
};
