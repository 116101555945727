import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.scss";
import LoginPage from "./modules/login";
import ProductsPage from "./modules/products";
import ProductPackshotsPage from "./modules/productPackshots";
import ProductPackshotsIngestionPage from "./modules/productPackshotsIngestion";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import BrandsPage from "modules/brands";
import BrandCategoriesPage from "modules/brandCategories";
import OtherObjectsPage from "modules/otherObjects";
import BrandFamiliesPage from "modules/brandFamilies";
import { mainTheme } from "shared/themes/main-theme";
import ClassificationsPage from "modules/classifications";
import SubClassificationsPage from "modules/subClassifications";
import CategoriesPage from "modules/categories/categoriesPage";
import LabelsPage from "modules/labels";
import PredefinedClassesPage from "modules/predefinedClasses";
import PredefinedClassLabelsPage from "modules/predefinedClasses/predefinedClassLabels/predefinedClassLabelsPage";
import SuppliersPage from "modules/suppliers/suppliersIndex";
import ImportsPage from "modules/imports/importsPage";
import ExportsPage from "modules/exports/exportsPage";
import { FormMode } from "models/util";
import { Protected } from "ProtectedRoute";
import { AuthProvider } from "context/authenticationContext";
import { PermissionProvider } from "context/permissionsContext";
import ProductCatalogsPage from "modules/productCatalogs";
import CustomAttributesPage from "modules/productCatalogs/customAttributes/customAttributesPage";
import ModelSubscriptionsPage from "modules/productCatalogs/modelSubscriptions/modelSubscriptionsPage";
import ModelListsPage from "modules/modelLists";
import { ModelListsProvider } from "context/modelListsContext";
import { ModelListProductsProvider } from "context/modelListProductsContext";
import ModelListProductsPage from "modules/modelLists/modelListProducts/modelListProductsPage";
import ForbiddenPage from "modules/forbiddenPage";
import RegionsPage from "modules/regions";
import PackSizesPage from "modules/packSizes";
import SizesPage from "modules/sizes";
import PackTypesPage from "modules/packTypes";
import * as Sentry from "@sentry/react";
import { initialState } from "reducers/authReducer";
import EditPredefinedClass from "modules/predefinedClasses/editPredefinedClass";
import { PredefinedClassesProvider } from "context/predefinedClassesContext";
import { PredefinedClassLabelsProvider } from "context/predefinedClassLabelsContext";
import { LabelsProvider } from "context/labelsContext";
import { ProductCatalogProvider } from "context/productCatalogContext";
import "bootstrap/dist/js/bootstrap.min.js";

if (
  window.location.hostname !== "localhost" &&
  window.location.hostname !== "127.0.0.1"
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH_DOMAIN as string}
    clientId={process.env.REACT_APP_ID as string}
    useRefreshTokens={true}
    cacheLocation="localstorage"
    // useRefreshTokensFallback={true}
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH_AUDIENCE as string,
      scope: process.env.REACT_APP_AUTH_SCOPE as string,
    }}
  >
    <ThemeProvider theme={mainTheme}>
      <AuthProvider>
        <PermissionProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  !initialState.isAuthenticated ? (
                    <LoginPage />
                  ) : (
                    <ProductsPage mode={FormMode.None} />
                  )
                }
              />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/403" element={<ForbiddenPage />} />
              <Route
                path="products"
                element={
                  <Protected>
                    <ProductsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="product-packshots"
                element={
                  <Protected>
                    <ProductPackshotsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="products/new"
                element={
                  <Protected>
                    <ProductsPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="products/:previewId"
                element={
                  <Protected>
                    <ProductsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="products/edit/:editId"
                element={
                  <Protected>
                    <ProductsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brands"
                element={
                  <Protected>
                    <BrandsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brands/new"
                element={
                  <Protected>
                    <BrandsPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brands/:previewId"
                element={
                  <Protected>
                    <BrandsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brands/edit/:editId"
                element={
                  <Protected>
                    <BrandsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brand-categories"
                element={
                  <Protected>
                    <BrandCategoriesPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brand-categories/new"
                element={
                  <Protected>
                    <BrandCategoriesPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brand-categories/:previewId"
                element={
                  <Protected>
                    <BrandCategoriesPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brand-categories/edit/:editId"
                element={
                  <Protected>
                    <BrandCategoriesPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="other-objects"
                element={
                  <Protected>
                    <OtherObjectsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="other-objects/edit/:editId"
                element={
                  <Protected>
                    <OtherObjectsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="other-objects/:previewId"
                element={
                  <Protected>
                    <OtherObjectsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="other-objects/new"
                element={
                  <Protected>
                    <OtherObjectsPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brand-families"
                element={
                  <Protected>
                    <BrandFamiliesPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brand-families/new"
                element={
                  <Protected>
                    <BrandFamiliesPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brand-families/:previewId"
                element={
                  <Protected>
                    <BrandFamiliesPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="brand-families/edit/:editId"
                element={
                  <Protected>
                    <BrandFamiliesPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="classifications"
                element={
                  <Protected>
                    <ClassificationsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="classifications/new"
                element={
                  <Protected>
                    <ClassificationsPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="classifications/:previewId"
                element={
                  <Protected>
                    <ClassificationsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="classifications/edit/:editId"
                element={
                  <Protected>
                    <ClassificationsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="subclassifications"
                element={
                  <Protected>
                    <SubClassificationsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="subclassifications/new"
                element={
                  <Protected>
                    <SubClassificationsPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="subclassifications/:previewId"
                element={
                  <Protected>
                    <SubClassificationsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="subclassifications/edit/:editId"
                element={
                  <Protected>
                    <SubClassificationsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="categories"
                element={
                  <Protected>
                    <CategoriesPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="categories/new"
                element={
                  <Protected>
                    <CategoriesPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="categories/:previewId"
                element={
                  <Protected>
                    <CategoriesPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="categories/edit/:editId"
                element={
                  <Protected>
                    <CategoriesPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="pack-sizes"
                element={
                  <Protected>
                    <PackSizesPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="pack-sizes/new"
                element={
                  <Protected>
                    <PackSizesPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="pack-sizes/:previewId"
                element={
                  <Protected>
                    <PackSizesPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="pack-sizes/edit/:editId"
                element={
                  <Protected>
                    <PackSizesPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="sizes"
                element={
                  <Protected>
                    <SizesPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="pack-types"
                element={
                  <Protected>
                    <PackTypesPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="sizes/new"
                element={
                  <Protected>
                    <SizesPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="pack-types/new"
                element={
                  <Protected>
                    <PackTypesPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="sizes/:previewId"
                element={
                  <Protected>
                    <SizesPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="pack-types/:previewId"
                element={
                  <Protected>
                    <PackTypesPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="sizes/edit/:editId"
                element={
                  <Protected>
                    <SizesPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="pack-types/edit/:editId"
                element={
                  <Protected>
                    <PackTypesPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="regions"
                element={
                  <Protected>
                    <RegionsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="regions/new"
                element={
                  <Protected>
                    <RegionsPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="regions/:previewId"
                element={
                  <Protected>
                    <RegionsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="regions/edit/:editId"
                element={
                  <Protected>
                    <RegionsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="labels"
                element={
                  <Protected>
                    <LabelsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="labels/new"
                element={
                  <Protected>
                    <LabelsPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="labels/:previewId"
                element={
                  <Protected>
                    <LabelsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="labels/edit/:editId"
                element={
                  <Protected>
                    <LabelsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="predefined-classes"
                element={
                  <Protected>
                    <PredefinedClassesPage mode={FormMode.None} />
                  </Protected>
                }
              />
              <Route
                path="predefined-classes/new"
                element={
                  <Protected>
                    <PredefinedClassesPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="predefined-classes/add-via-csv/:csvid"
                element={
                  <Protected>
                    <PredefinedClassesPage mode={FormMode.AddCsv} />{" "}
                  </Protected>
                }
              />
              <Route
                path="predefined-classes/edit/:editId"
                element={
                  <Protected>
                    <PredefinedClassesPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="predefined-classes/:previewId/labels"
                element={
                  <Protected>
                    <PredefinedClassesProvider>
                      <PredefinedClassLabelsProvider>
                        <PredefinedClassLabelsPage mode={FormMode.View} />
                      </PredefinedClassLabelsProvider>
                    </PredefinedClassesProvider>
                  </Protected>
                }
              />
              <Route
                path="predefined-classes/edit-labels/:previewId"
                element={
                  <Protected>
                    <PredefinedClassesProvider>
                      <LabelsProvider>
                        <EditPredefinedClass mode={FormMode.View} />
                      </LabelsProvider>
                    </PredefinedClassesProvider>
                  </Protected>
                }
              />
              <Route
                path="model-lists"
                element={
                  <Protected>
                    <ModelListsPage mode={FormMode.None} />
                  </Protected>
                }
              />
              <Route
                path="model-lists/:previewId/products"
                element={
                  <Protected>
                    <ModelListsProvider>
                      <ModelListProductsProvider>
                        <ModelListProductsPage mode={FormMode.None} />
                      </ModelListProductsProvider>
                    </ModelListsProvider>
                  </Protected>
                }
              />
              <Route
                path="suppliers"
                element={
                  <Protected>
                    <SuppliersPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="suppliers/new"
                element={
                  <Protected>
                    <SuppliersPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="suppliers/:previewId"
                element={
                  <Protected>
                    <SuppliersPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="suppliers/edit/:editId"
                element={
                  <Protected>
                    <SuppliersPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="imports"
                element={
                  <Protected>
                    <ImportsPage />{" "}
                  </Protected>
                }
              />
              <Route
                path="exports"
                element={
                  <Protected>
                    <ExportsPage />{" "}
                  </Protected>
                }
              />
              // TODO: rename the url param to catalogId /* Customer Product
              Lists (previously Product Catalog) Routes*/
              <Route
                path="customer-product-lists/custom-attributes/:catalogId"
                element={
                  <Protected>
                    <ProductCatalogProvider>
                      <CustomAttributesPage mode={FormMode.None} />
                    </ProductCatalogProvider>
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/custom-attributes/:catalogId/edit/:editCustomAttributeId"
                element={
                  <Protected>
                    <CustomAttributesPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/custom-attributes/:catalogId/new"
                element={
                  <Protected>
                    <CustomAttributesPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/model-subscriptions/:catalogId"
                element={
                  <Protected>
                    <ModelSubscriptionsPage mode={FormMode.None} />
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/model-subscriptions/:catalogId/new"
                element={
                  <Protected>
                    <ModelSubscriptionsPage mode={FormMode.Create} />
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/model-subscriptions/:catalogId/edit/:editModelSubscriptionId"
                element={
                  <Protected>
                    <ModelSubscriptionsPage mode={FormMode.Edit} />
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/products"
                element={
                  <Protected>
                    <ProductCatalogsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/products/new"
                element={
                  <Protected>
                    <ProductCatalogsPage mode={FormMode.Create} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/products/edit/:editId"
                element={
                  <Protected>
                    <ProductCatalogsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/products/:previewId"
                element={
                  <Protected>
                    <ProductCatalogsPage mode={FormMode.None} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/product/edit/:catalogId"
                element={
                  <Protected>
                    <ProductCatalogsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/products/:previewId/edit/:editProductId/:editProductInstanceId"
                element={
                  <Protected>
                    <ProductCatalogsPage mode={FormMode.Edit} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/products/:previewId/view/:viewProductId/:viewProductInstanceId"
                element={
                  <Protected>
                    <ProductCatalogsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="customer-product-lists/products/:previewId/view/:viewProductId"
                element={
                  <Protected>
                    <ProductCatalogsPage mode={FormMode.View} />{" "}
                  </Protected>
                }
              />
              <Route
                path="packshots-ingestion"
                element={
                  <Protected>
                    <ProductPackshotsIngestionPage />{" "}
                  </Protected>
                }
              />
            </Routes>
          </BrowserRouter>
        </PermissionProvider>
      </AuthProvider>
    </ThemeProvider>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
