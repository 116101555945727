import { SortDescriptor } from "@progress/kendo-data-query";
import moment from "moment";

export type Nullable<T> = T | null;
export type Meta = {
  total_count: number;
  page_size: number;
  current_page: number;
};
export enum FormMode {
  View,
  Edit,
  Create,
  None,
  AddCsv,
}
export const DEFAULT_ERROR = "Something went wrong!";

export const clearActionSuccess = (dispatch: any) => {
  dispatch({ type: "clearActionSuccess" });
};
export const clearActionFailure = (dispatch: any) => {
  dispatch({ type: "clearActionFailure" });
};
export const setLoading = (dispatch: any) => {
  dispatch({
    type: "request",
  });
};

export const setFailure = (dispatch: any, errorMessage: string) => {
  dispatch({
    type: "failure",
    error: errorMessage,
  });
};

export const setForbiddenError = (dispatch: any, message: string) => {
  dispatch({ type: "setForbiddenError", message: message });
};

export const truncate = (str: string) => {
  return str.length > 60 ? str.substring(0, 57) + "..." : str;
};

export const convertFiltersToQueryString = (arr: any): string => {
  return arr.reduce((acc: any, curr: any) => {
    if (!curr.value) return acc;
    if (isDateFilter(curr.field)) {
      acc += handleDateFilter(curr);
    } else {
      acc += `${encodeURIComponent(convertFieldToFilterName(curr.field))}=${encodeURIComponent(curr.value)}&`;
    }
    return "&" + acc;
  }, "");
};
const isDateFilter = (filterName: any) => {
  return (
    filterName === "created_at" ||
    filterName === "updated_at" ||
    filterName === "added_at"
  );
};

const handleDateFilter = (filter: any) => {
  if (filter.value === undefined) return "";
  if (filter.field === "created_at") {
    return `created_at_start_at=${moment(filter.value.start).format("YYYY-MM-DD")}T00:00:00&created_at_end_at=${moment(filter.value.end).format("YYYY-MM-DD")}T23:59:59&`;
  }
  if (filter.field === "updated_at") {
    return `updated_at_start_at=${moment(filter.value.start).format("YYYY-MM-DD")}T00:00:00&updated_at_end_at=${moment(filter.value.end).format("YYYY-MM-DD")}T23:59:59&`;
  }
  if (filter.field === "added_at") {
    return `added_at_start_at=${moment(filter.value.start).format("YYYY-MM-DD")}T00:00:00&added_at_end_at=${moment(filter.value.end).format("YYYY-MM-DD")}T23:59:59&`;
  }
};

// some of our field are stored as foo, but are searchable as foo_name
// this function converts the field given name into searchable name
export const convertFieldToFilterName = (field: string) => {
  if (
    field === "brand" ||
    field === "category" ||
    field === "brand_family" ||
    field === "classification" ||
    field === "subclassification" ||
    field === "supplier" ||
    field === "labels" ||
    field === "region"
  ) {
    return field + "_name";
  }
  return field;
};

export interface GetEntitiesProps {
  page: number;
  take: number;
  filters?: any[];
  sort?: SortDescriptor[];
}

export const generateSortQueryParam = (sort: SortDescriptor[]): string => {
  if (sort.length) {
    sort as SortDescriptor[];
    return "&sort_by=" + sort[0].field + "&order=" + sort[0].dir;
  } else return "";
};
