import CommonLayout from "components/layouts/common-layout";
import { ClassificationsTable } from "components/classifications/clasificationsTable";
import { ClassificationsProvider } from "context/classificationsContext";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AddClassifications from "./addClassification";
import EditClassifications from "./editClassification";
import ViewClassifications from "./viewClassification";
import { Drawer } from "@mui/material";
import { FormMode } from "models/util";
import { useAuth0 } from "@auth0/auth0-react";

interface ClassificationsProps {
  mode: FormMode;
}

const ClassificationsPage = (props: ClassificationsProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const [drawerTitle, setDrawerTitle] = useState<string>("Classification");
  let { editId, previewId } = useParams();
  const [classification, setClassification] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode);
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setClassification(null);
      setMode(FormMode.Edit);
      getClassificationById(editId);
    }
  }, [editId]);

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getClassificationById(previewId);
    }
  }, [previewId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Classification");
    } else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Classification");
    } else {
      setDrawerTitle("Classification");
    }
  }, [mode]);

  const getClassificationById = async (id: string) => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/classifications/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    return setClassification(res.data.data);
  };

  const onExit = () => {
    setMode(FormMode.None);
    setClassification(null);
    navigate("/classifications", { replace: true });
  };

  return (
    <CommonLayout>
      <h2 className="float-start">Classifications</h2>
      <button
        onClick={() => {
          navigate("/classifications/new", { replace: true });
          setMode(FormMode.Create);
        }}
        className="btn btn-primary btn-block fw-bolder float-end"
      >
        <AddIcon />
        New Classification
      </button>
      <ClassificationsProvider>
        <div className="clearfix"></div>
        <ClassificationsTable />
        <Drawer
          PaperProps={{ style: { width: "600px" } }}
          anchor={"right"}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" />
              <span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && (
              <ViewClassifications classification={classification} />
            )}
            {mode === FormMode.Create && <AddClassifications onExit={onExit} />}
            {mode === FormMode.Edit && classification && (
              <EditClassifications
                onExit={onExit}
                classification={classification}
              />
            )}
          </section>
        </Drawer>
      </ClassificationsProvider>
    </CommonLayout>
  );
};

export default ClassificationsPage;
