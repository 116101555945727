import axios from "axios";
import { useNavigate } from "react-router-dom";
import { convertFiltersToQueryString } from "models/util";

export const useExportRequests = () => {
  const navigate = useNavigate();
  const requestExport = async (
    exporter: String,
    columns: any,
    filters: any,
    context: any,
  ) => {
    // TODO: Where to get user?
    let convertedFilters = "";
    if (filters && filters.length > 0) {
      convertedFilters = convertFiltersToQueryString(filters);
    }

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/exports`, {
      exporter: exporter,
      columns: columns || [],
      context: context || {},
      filters: convertedFilters,
      user: {},
    });
    navigate("/exports", { replace: true });
  };

  return [requestExport];
};
