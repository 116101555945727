import { ChangeEvent, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";

interface SmartEditProps {
  register: any;
  productValue: any;
  instanceValue: any;
  onValueChanged: any;
  onRestore: any;
}

const SmartEdit = (props: SmartEditProps) => {
  useEffect(() => {
    setIdDisabled(!props.instanceValue || Object.is(props.instanceValue, null));
  }, [props.instanceValue]);

  const [isDisabled, setIdDisabled] = useState(false);

  return (
    <div className="smart-editor relative pb-x-1">
      <input
        className="form-control"
        {...props.register}
        required
        disabled={isDisabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          props.onValueChanged(e.target.value)
        }
      />
      {isDisabled && (
        <EditIcon
          onClick={() => {
            setIdDisabled(false);
          }}
          className="smart-edit-icon"
          fontSize="small"
        />
      )}
      {!isDisabled && (
        <RefreshIcon
          onClick={() => {
            setIdDisabled(true);
            props.onRestore();
          }}
          className="smart-edit-icon"
          fontSize="small"
        />
      )}
      {!isDisabled && (
        <span className="lighter-gray smart-p-left">{props.productValue}</span>
      )}
    </div>
  );
};

export default SmartEdit;
