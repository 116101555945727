import axios from "axios";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { exportReducer, initialState } from "reducers/exportsReducer";
import {
  convertFiltersToQueryString,
  Meta,
  generateSortQueryParam,
  GetEntitiesProps,
} from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";
import { Export } from "models/export";

const ExportsContext = createContext(initialState);

interface Props {
  children?: ReactNode;
}

export const ExportsProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(exportReducer, initialState);

  const getExports = ({
    page = 1,
    take = 20,
    filters = [],
    sort = [],
  }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading();
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/exports/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`,
      )
      .then(
        (res) => {
          setExports(
            processDateObjects(res.data.data) as Export[],
            res.data.meta,
          );
        },
        () => setFailure("Error getting exports"),
      );
  };

  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" });
  };
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" });
  };

  const setExports = (exports: Export[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { exports: exports, meta },
    });
  };

  const setLoading = () => {
    dispatch({
      type: "request",
    });
  };

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage,
    });
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    exports: state.exports,
    meta: state.meta,
    setExports: setExports,
    getExports: getExports,
    setLoading,
    setFailure,
    isSuccess: state.isSuccess,
    message: state.message,
  };
  return (
    <ExportsContext.Provider value={value}>{children}</ExportsContext.Provider>
  );
};

const useExports = () => {
  const context = useContext(ExportsContext);

  if (context === undefined) {
    throw new Error("useExports must be used within ExportsContext");
  }

  return context;
};

export default useExports;
