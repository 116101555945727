import { useForm } from "react-hook-form";
import useCustomAttributes from "context/customAttributesContext";
import { SaveButton } from "components/shared/saveButton";
import LockIcon from "@mui/icons-material/Lock";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const EditCustomAttribute = (props: any) => {
  const { editCustomAttribute } = useCustomAttributes();
  let { catalogId, editCustomAttributeId } = useParams();
  let location = useLocation();
  const customAttribute = location.state.customAttribute;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: customAttribute.name || "",
      type: customAttribute.data_type || "",
      description: "",
    },
  });

  return (
    <form
      className={errors.name ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((data: any) => {
        data.id = customAttribute.id;
        editCustomAttribute(data, catalogId, editCustomAttributeId).then(
          (error: any) => {
            if (!error) {
              props.onExit();
            }
          },
        );
      })}
      noValidate
    >
      <div className="card my-3">
        <div className="my-3 px-3">
          <label className="form-label">Name</label>
          <input
            className="form-control"
            {...register("name", { required: true })}
            required
          />
          {errors.name && (
            <div className="invalid-feedback">
              <p className="warning-icon">This field is required.</p>
            </div>
          )}
        </div>
        <div className="my-3 px-3">
          <label className="form-label">
            Data Type &nbsp; <LockIcon fontSize="inherit" />
          </label>
          <input
            className="form-control"
            disabled
            value={customAttribute.data_type}
          />
        </div>
      </div>
      <div className="bottom-bar">
        <SaveButton />
      </div>
    </form>
  );
};

export default EditCustomAttribute;
