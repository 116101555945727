import { useState } from "react";
import { useFileUpload } from "hooks/useFileUpload";
import { DropZone } from "components/shared/dropZone";
import { BootstrapButton } from "components/shared/bootstrapButton";
import { modalStyle } from "components/shared/modalStyles";
import { Box, Modal, Autocomplete, TextField, Chip } from "@mui/material";
import useModelLists from "context/modelListsContext";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DescriptionIcon from "@mui/icons-material/Description";

interface AddEditProductsModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeAddEditProductsModal: () => void;
}

export const AddEditProductsModal = (props: AddEditProductsModalProps) => {
  const [file, setFile] = useState<Blob>();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadFile] = useFileUpload();
  const [selectedModelLists, setSelectedModelLists] = useState<any[]>([]);
  const { modelLists, searchModelLists } = useModelLists();

  const handleClose = () => {
    setFile(undefined);
    props.onClose();
  };

  const onFileAdded = (file: any) => {
    setFile(file);
  };

  const handleUpload = (import_type: string) => {
    setIsUploading(true);
    const options = {
      import_type: import_type,
      import_strategies: [],
      context: { model_list_ids: selectedModelLists.map((list) => list.id) },
    };
    uploadFile(file, options).then(() => {
      setIsUploading(false);
    });
  };

  const getModelListsForName = (searchTerm: string) => {
    searchModelLists({ filters: [{ field: "name", value: searchTerm }] });
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 className="float-start normal-header">
          <ClearOutlinedIcon
            onClick={handleClose}
            className="primary-col pointer"
            style={{ marginTop: "-4px" }}
          />
          <span style={{ marginLeft: "40px" }}>
            Add or Edit Products via CSV
          </span>
        </h2>
        <div className="clearfix"></div>
        <DropZone onFileAdded={onFileAdded} />
        <div className="card my-3">
          <div className="card-body">
            <label className="form-label">Model Lists</label>
            <Autocomplete
              multiple
              options={modelLists}
              getOptionLabel={(option) => option.name}
              value={selectedModelLists}
              onChange={(event, newValue) => {
                setSelectedModelLists(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                getModelListsForName(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Model Lists"
                  placeholder="Search model lists"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    key={option.id}
                  />
                ))
              }
            />
          </div>
        </div>
        <div className="card my-3">
          <div className="card-body">
            <DescriptionIcon
              className="primary-col float-left"
              style={{ fontSize: 30 }}
            />
            <p className="m-0 float-left middle-par">
              Example of creating new products
            </p>
            <a
              type="button"
              href={`${process.env.REACT_APP_API_BASE_URL}/imports/create_products.csv`}
              className="btn btn-sm btn-secondary float-right"
            >
              Download
            </a>
          </div>
        </div>
        <div className="card my-3">
          <div className="card-body">
            <DescriptionIcon
              className="primary-col float-left"
              style={{ fontSize: 30 }}
            />
            <p className="m-0 float-left middle-par">
              Example of updating existing products
            </p>
            <a
              type="button"
              href={`${process.env.REACT_APP_API_BASE_URL}/imports/update_products.csv`}
              className="btn btn-sm btn-secondary float-right"
            >
              Download
            </a>
          </div>
        </div>
        {!isUploading && !!file && (
          <>
            <BootstrapButton
              variant="contained"
              onClick={() => handleUpload("products")}
            >
              <FileUploadIcon fontSize="small" />
              <label>Upload</label>
            </BootstrapButton>
          </>
        )}
        {isUploading && (
          <>
            <BootstrapButton
              disabled
              variant="contained"
              onClick={() => handleUpload("products")}
            >
              <label>In progress...</label>
            </BootstrapButton>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default AddEditProductsModal;
