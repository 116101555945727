import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";

const ViewOtherObject = (props: any) => {
  const { otherObject } = props;

  return (
    <>
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <p className="mb-0">{otherObject?.name}</p>
        </div>
      </div>
      <div className="card my-3">
        <div className="card-body">
          <label className="form-label">Labels</label>
          <ul>
            {otherObject?.labels?.map((l: any) => (
              <li key={l.id}>
                {" "}
                <NavLink to={`/labels/${l.id}`} replace={true}>
                  {l.name}
                </NavLink>
                <br />
                {l.id}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <NavLink to={`/other-objects/edit/${otherObject?.id}`} replace={true}>
        <button
          type="button"
          className="save-ui btn btn-primary btn-block fw-bolder float-end"
        >
          <EditIcon fontSize="small" />
          Edit
        </button>
      </NavLink>
    </>
  );
};

export default ViewOtherObject;
