import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  children?: React.ReactNode;
}

export const Protected = ({ children }: Props) => {
  const { pathname, search } = useLocation();
  const { isLoading, isAuthenticated } = useAuth0();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
};
