import { ProductAttribute } from "models/product";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface CustomAttributesState {
  isLoading: boolean;
  customAttributes: ProductAttribute[];
  meta?: Meta;
  metaCustomAttributes: Meta;
  error?: string;
  getCustomAttributes?: any;
  createCustomAttribute?: any;
  editCustomAttribute?: any;
  isSuccess?: boolean;
  message?: string;
  clearActionSuccess?: any;
  removeCustomAttributeById?: any;
  resetCustomAttributes?: any;
}

export const initialState: CustomAttributesState = {
  isLoading: true,
  customAttributes: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
  metaCustomAttributes: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: "request" }
  | {
      type: "success";
      results: {
        customAttributes: ProductAttribute[];
        meta?: Meta;
        metaCustomAttributes: Meta;
      };
    }
  | { type: "failure"; error?: string }
  | { type: "actionSuccess"; message: string }
  | { type: "clearActionSuccess" }
  | { type: "clearActionFailure" }
  | { type: "resetCustomAttributes" };

export function customAttributeReducer(
  state: CustomAttributesState,
  action: Action,
): CustomAttributesState {
  switch (action.type) {
    case "request":
      return {
        ...state,
        customAttributes: [],
        isLoading: true,
        error: undefined,
      };
    case "success":
      return {
        ...state,
        isLoading: false,
        customAttributes: action.results.customAttributes,
        meta: action.results.meta,
        metaCustomAttributes: action.results.metaCustomAttributes,
      };
    case "failure":
      return {
        ...state,
        isLoading: false,
        error: action.error || DEFAULT_ERROR,
      };
    case "actionSuccess":
      return {
        ...state,
        isSuccess: true,
        message: action.message,
        error: undefined,
      };
    case "clearActionSuccess":
      return { ...state, isSuccess: false, message: undefined };
    case "clearActionFailure":
      return { ...state, error: undefined };
    case "resetCustomAttributes":
      return { ...state, customAttributes: [], error: undefined };
  }
}
