import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        console.log("Fetched token", token);
      } catch (e) {
        console.error("Failed to get token:", e);
      }
    };

    if (isAuthenticated) {
      fetchToken();
    }
  }, [user, isAuthenticated, getAccessTokenSilently]);

  const logoutHandler = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <>
      {isAuthenticated && (
        <ul className="nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Profile
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              <div className="dropdown-item">
                <strong>{user?.name}</strong>
                <p>{user?.email}</p>
              </div>
              <hr className="dropdown-divider" />
              <a className="dropdown-item" href="#" onClick={logoutHandler}>
                Logout
              </a>
            </div>
          </li>
        </ul>
      )}
    </>
  );
};

export default Profile;
