import { Drawer } from "@mui/material";
import CommonLayout from "components/layouts/common-layout";
import { PackTypesTable } from "components/packTypes/packTypesTable";
import { PackTypesProvider } from "context/packTypesContext";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AddPackType from "./addPackType";
import EditPackType from "./editPackType";
import ViewPackType from "./viewPackType";
import { FormMode } from "models/util";

interface PackTypesProps {
  mode: FormMode;
}

const PackTypesPage = (props: PackTypesProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Pack Type");
  let { editId, previewId } = useParams();
  const [packType, setPackType] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode);
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setPackType(null);
      setMode(FormMode.Edit);
      getPackTypeById(editId).then((res: any) => {
        setPackType(res.data.data);
      });
    }
  }, [editId]);

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getPackTypeById(previewId).then((res: any) => {
        setPackType(res.data.data);
      });
    }
  }, [previewId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Pack Type");
    } else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Pack Type");
    } else {
      setDrawerTitle("Pack Type");
    }
  }, [mode]);

  const getPackTypeById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/pack_types/${id}`);
  };

  const onExit = () => {
    setMode(FormMode.None);
    navigate("/pack-types", { replace: true });
  };

  return (
    <CommonLayout>
      <h2 className="float-start">Pack Types</h2>
      <button
        onClick={() => {
          navigate("/pack-types/new", { replace: true });
          setMode(FormMode.Create);
        }}
        className="btn btn-primary btn-block fw-bolder float-end"
      >
        <AddIcon />
        New Pack Type
      </button>
      <PackTypesProvider>
        <div className="clearfix"></div>
        <PackTypesTable />
        <Drawer
          PaperProps={{ style: { width: "600px" } }}
          anchor={"right"}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" />
              <span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && <ViewPackType packType={packType} />}
            {mode === FormMode.Create && <AddPackType onExit={onExit} />}
            {mode === FormMode.Edit && packType && (
              <EditPackType onExit={onExit} packType={packType} />
            )}
          </section>
        </Drawer>
      </PackTypesProvider>
    </CommonLayout>
  );
};

export default PackTypesPage;
