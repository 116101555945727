import CommonLayout from "components/layouts/common-layout";
import { ModelListsTable } from "components/modelLists/modelListsTable";
import { ModelListsProvider } from "context/modelListsContext";
import { FormMode } from "models/util";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ModelListProductsProvider } from "context/modelListProductsContext";
import { useAuth0 } from "@auth0/auth0-react";

interface ModelListsProps {
  mode: FormMode;
}

const ModelListsPage = (props: ModelListsProps) => {
  const { getAccessTokenSilently } = useAuth0();
  let { previewId } = useParams();
  const [modelList, setModelList] = useState<any>({});

  useEffect(() => {
    if (previewId) {
      setModelList(null);
      getModelListById(previewId);
    }
  }, [previewId]);

  const getModelListById = async (id: string) => {
    const token = await getAccessTokenSilently();
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/model_lists?id=${id}`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
    return setModelList(res.data.data[0]);
  };

  return (
    <CommonLayout>
      <h2 className="float-start">Model Lists</h2>
      <ModelListsProvider>
        <ModelListProductsProvider>
          <div className="clearfix"></div>
          <ModelListsTable />
        </ModelListProductsProvider>
      </ModelListsProvider>
    </CommonLayout>
  );
};

export default ModelListsPage;
