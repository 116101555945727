import { NameValue } from "models/name-value-pair";
import Moment from "moment";

export const processDateObjects = (dateObjects: NameValue[]) => {
  return dateObjects.map((o: NameValue) => {
    return prodcessDateObject(o);
  });
};

export const prodcessDateObject = (dateObject: NameValue) => {
  dateObject.created_at = Moment(dateObject.created_at).format(
    SHELF_DATE_FORMAT,
  );
  dateObject.updated_at = Moment(dateObject.updated_at).format(
    SHELF_DATE_FORMAT,
  );
  return dateObject;
};

export const processCustomDateObject = (
  dateObjects: any[],
  propertyName: string,
) => {
  return dateObjects.map((o: any) => {
    o[propertyName] = Moment(o[propertyName]).format(SHELF_DATE_FORMAT);
    return o;
  });
};

const SHELF_DATE_FORMAT = "DD-MM-YYYY HH:mm";
