import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { TagsProps } from "./tagsProps";
import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import React from "react";
import axios from "axios";
import { convertFiltersToQueryString } from "models/util";

interface NewTagInput {
  inputValue: string;
  name: string;
}
export const TagsSearch = (props: TagsProps) => {
  const [val, setVal] = useState([] as any[]);
  const [tags, setTags] = useState();

  const { selectedTags } = props;
  const { allowUserDefinedTags } = props || false;

  const filter = createFilterOptions<NewTagInput>();
  const getTagForName = async (name: string) => {
    const res = await getTags(1, 20, [{ field: "name", value: name }]);
    setTags(res.data.data);
  };
  const getTags = async (page: 1, take = 10, filters: any, sort?: any) => {
    const f = convertFiltersToQueryString(filters);
    return axios.get(
      `${process.env.REACT_APP_API_URL}/tags?page=${page}&items_per_page=${take}${f}`,
    );
  };
  useEffect(() => {
    getTagForName("");
  }, []);

  useEffect(() => {
    if (selectedTags) {
      setVal([...selectedTags] as any);
    }
  }, [selectedTags]);

  return (
    <>
      <Autocomplete
        onInputChange={(event, newInputValue) => {
          getTagForName(newInputValue);
        }}
        onChange={(event: any, newValue: any | null) => {
          const selection = newValue.length
            ? newValue?.map((t: any) => {
                if (typeof t === "string") {
                  return t;
                } else return t.inputValue || t.name;
              })
            : [];
          props.onSelectionChange(selection);
        }}
        isOptionEqualToValue={(option, value) => {
          if (allowUserDefinedTags) {
            return false;
          }
          return option?.name === value?.name;
        }}
        getOptionLabel={(option: any) => {
          if (typeof option === "string") {
            return option;
          }
          return option?.name || "";
        }}
        freeSolo={allowUserDefinedTags}
        value={val}
        options={tags || []}
        multiple={true}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          if (allowUserDefinedTags && params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              name: `Create new tag: "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            value={selectedTags}
            size={"small"}
            placeholder="Search tags"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
};
