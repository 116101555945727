import { useState } from "react";
import { useForm } from "react-hook-form";
import useLabels from "context/labelsContext";
import { SaveButton } from "components/shared/saveButton";
import LockIcon from "@mui/icons-material/Lock";
import { TagsSearch } from "components/tags/tagsSearch";

const EditLabel = (props: any) => {
  const { editLabel } = useLabels();
  const [tagsList, setTagsList] = useState(props.label.tag_list || []);
  const changeTagList = (tagsList: string[]) => {
    setTagsList(tagsList);
    setValue("tag_list", tagsList);
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: props.label?.name || "",
      entity_name: props.label.entity_name || "",
      entity_type: props.label.entity_type || "",
      tag_list: tagsList || [],
    },
  });

  return (
    <form
      className={errors.name ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((data: any) => {
        editLabel(data, props.label.id).then((error: any) => {
          if (!error) {
            props.onExit();
          }
        });
      })}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <input
            className="form-control"
            {...register("name", { required: true })}
            required
          />
          {errors.name && (
            <div className="invalid-feedback">
              <p className="warning-icon">This field is required.</p>
            </div>
          )}
        </div>
      </div>
      <div className="card mt-4">
        <div className="card-body">
          <label className="form-label">
            Entity Type&nbsp; <LockIcon fontSize="inherit" />
          </label>
          <input
            className="form-control"
            {...register("entity_type", { required: true, disabled: true })}
            required
          />
        </div>
        <div className="card-body">
          <label className="form-label">
            Entity Name&nbsp; <LockIcon fontSize="inherit" />
          </label>
          <input
            className="form-control"
            {...register("entity_name", { required: true, disabled: true })}
            required
          />
        </div>

        <div className="card-body">
          <label className="form-label">Tags</label>
          <div className="pb-y-1">
            <TagsSearch
              onSelectionChange={changeTagList}
              selectedTags={tagsList}
              allowUserDefinedTags={true}
            />
          </div>
          <input type="hidden" {...register("tag_list")} />
        </div>
      </div>

      <SaveButton />
    </form>
  );
};

export default EditLabel;
