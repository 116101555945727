import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SaveButton } from "components/shared/saveButton";
import useProductCatalogs from "context/productCatalogContext";
import { FormControlLabel, Checkbox } from "@mui/material";

const AddProductCatalog = (props: any) => {
  const { createProductCatalog } = useProductCatalogs();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      key: "",
      create_key_automatically: true,
    },
  });

  const isAutomatic = watch("create_key_automatically");

  return (
    <form
      className={
        errors.name || errors.key ? "was-validated" : "needs-validation"
      }
      onSubmit={handleSubmit((data) => {
        if (data.create_key_automatically) {
          data.key = data.name.trim().replaceAll(" ", "_").toLowerCase();
        }
        createProductCatalog(data).then((error: any) => {
          if (!error) {
            props.onExit();
          }
        });
      })}
      noValidate
    >
      <div className="card">
        <div className="card-body">
          <label className="form-label">Name</label>
          <input
            className="form-control"
            {...register("name", { required: true })}
            required
          />
          {errors.name && (
            <div className="invalid-feedback">
              <p className="warning-icon">This field is required.</p>
            </div>
          )}
        </div>
        <div className="card-body">
          <FormControlLabel
            control={
              <Controller
                name={"create_key_automatically"}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={!!props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={"Create key automatically"}
          />
          {!isAutomatic && (
            <input
              className="form-control"
              {...register("key", { required: false })}
              required
            />
          )}
          {errors.key && (
            <div className="invalid-feedback">
              <p className="warning-icon">This field is required.</p>
            </div>
          )}
        </div>
      </div>

      <SaveButton />
    </form>
  );
};

export default AddProductCatalog;
