import React from "react";
import "./image-gallery.css";

interface ImageGalleryProps {
  photos?: Record<string, any>;
  handleImageClick: (originalUrl: string, key: string) => void;
}

const ImageGallery: React.FC<ImageGalleryProps> = ({
  photos,
  handleImageClick,
}) => {
  if (!photos) return null;

  const imageHeight = 250;
  const rowGap = 16;
  const maxHeight = imageHeight * 3 + rowGap * 2;

  return (
    <div
      style={{
        position: "relative",
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
        gap: `${rowGap}px`,
        justifyItems: "center",
        minHeight: "100px",
        maxHeight: `${maxHeight}px`,
      }}
    >
      {Object.entries(photos).map(([key, photo]) => {
        const displayUrl =
          photo.variants?.find((v: { name: string }) => v.name === "small")
            ?.url ||
          photo.original_url ||
          "";
        return (
          <div key={key} style={{ position: "relative", overflow: "visible" }}>
            <img
              src={displayUrl}
              alt={photo.filename}
              style={{
                width: "100%",
                height: `${imageHeight}px`,
                objectFit: "cover",
                cursor: "pointer",
                border: "1px solid rgba(221, 221, 221, 0.5)",
                transition: "transform 0.2s",
                position: "relative",
                zIndex: 0,
              }}
              onClick={() => handleImageClick(photo.original_url, key)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ImageGallery;
