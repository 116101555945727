import axios from "axios";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { ProductAttribute } from "../models/product";
import {
  productCatalogProductReducer,
  initialState,
} from "reducers/productCatalogProductsReducer";
import {
  clearActionFailure,
  clearActionSuccess,
  convertFiltersToQueryString,
  generateSortQueryParam,
  Meta,
  setFailure,
  setLoading,
} from "models/util";
import { Product } from "models/product";
import { SortDescriptor } from "@progress/kendo-data-query";
import { processCustomDateObject } from "shared/functions/processDateObjects";

const ProductCatalogContext = createContext(initialState);

interface Props {
  children?: ReactNode;
}

interface productCatalogProps {
  catalogId?: string;
  page: number;
  take: number;
  filters?: any;
  sort?: SortDescriptor[];
}

const ADDED_AT = "added_at";
const CUSTOM_ATTR_DEFAULT_ERROR = "Something went wrong";

export const ProductCatalogProductsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(
    productCatalogProductReducer,
    initialState,
  );
  const formatCustomAttrError = (error: string, name: string) => {
    const msg = `Failed to update ${name}, error message: ${error}; `;
    return [msg];
  };

  const getProductCatalogProducts = ({
    catalogId = undefined,
    page = 1,
    take = 20,
    filters = [],
    sort = [],
  }: productCatalogProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/products?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`,
      )
      .then(
        (res) => {
          setProductCatalogProducts(
            processCustomDateObject(res?.data?.data, ADDED_AT),
            res?.data?.meta,
          );
        },
        () => setFailure(dispatch, "Error getting Customer Product List"),
      );
  };

  const setProductCatalogProducts = (
    productCatalogProducts: Product[],
    meta: Meta,
  ) => {
    dispatch({
      type: "success",
      results: { productCatalogProducts, metaProductCatalogProducts: meta },
    });
  };

  const removeProductCatalogProductById = async (
    catalogId: string,
    productId: string,
    instanceId?: string,
  ) => {
    if (instanceId) {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/products/${productId}/instances/${instanceId}`,
      );
    }
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/products/${productId}`,
    );
    dispatch({
      type: "actionSuccess",
      message: "Product has been removed from the Catalog!",
    });
    setTimeout(
      () =>
        getProductCatalogProducts({ catalogId: catalogId, page: 1, take: 20 }),
      2000,
    );
    setTimeout(() => clearActionSuccess(dispatch), 5000);
  };

  const editProductCatalogProduct = (
    p: any,
    catalogId: string,
    productId: string,
  ) => {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/products/${productId}/instances/${p.id}`,
        p,
      )
      .then(
        (res) => {
          dispatch({ type: "resetProducts" });
          if (state.attrErrors && state.attrErrors.length === 0) {
            dispatch({
              type: "actionSuccess",
              message: "Product has been updated!",
            });
          }
          setTimeout(
            () => getProductCatalogProducts({ catalogId, page: 1, take: 20 }),
            2000,
          );
          setTimeout(() => clearActionSuccess(dispatch), 5000);
        },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message });
          } else {
            dispatch({ type: "failure" });
          }
          setTimeout(() => clearActionFailure(dispatch), 5000);
          return error;
        },
      );
  };

  const createProductCatalogProduct = (
    p: any,
    catalogId: string,
    productId: string,
  ) => {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/products/${productId}/instances`,
        p,
      )
      .then(
        (res) => {
          dispatch({ type: "resetProducts" });
          if (state.attrErrors && state.attrErrors.length === 0) {
            dispatch({
              type: "actionSuccess",
              message: "Product has been updated!",
            });
          }
          setTimeout(
            () => getProductCatalogProducts({ catalogId, page: 1, take: 20 }),
            2000,
          );
          setTimeout(() => clearActionSuccess(dispatch), 5000);
        },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message });
          } else {
            dispatch({ type: "failure" });
          }
          setTimeout(() => clearActionFailure(dispatch), 5000);
          return error;
        },
      );
  };

  const createAttributeValue = (
    catalogId: string,
    productId: string,
    productAttribute: ProductAttribute,
  ) => {
    const params = {
      value: productAttribute.value,
      attribute_definition_id: productAttribute.attribute_definition_id,
    };

    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/products/${productId}/attribute_values`,
        params,
      )
      .then(
        (res) => {
          if (!state.attrErrors || state.attrErrors.length === 0) {
            dispatch({
              type: "actionSuccess",
              message: "Product has been updated!",
            });
          }
        },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({
              type: "attributeErrors",
              attrErrors: formatCustomAttrError(
                error.errors[0].message,
                productAttribute.name,
              ),
            });
          } else {
            dispatch({
              type: "attributeErrors",
              attrErrors: formatCustomAttrError(
                `${CUSTOM_ATTR_DEFAULT_ERROR}`,
                productAttribute.name,
              ),
            });
          }
          return error;
        },
      );
  };

  const editAttributeValue = (
    catalogId: string,
    productId: string,
    productAttribute: ProductAttribute,
  ) => {
    const formatEmptyStringtoNull = (value: string | string[]) => {
      if (value.length !== 0) {
        return value;
      } else {
        return null;
      }
    };
    const params = { value: formatEmptyStringtoNull(productAttribute.value) };

    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/products/${productId}/attribute_values/${productAttribute.id}`,
        params,
      )
      .then(
        (res) => {
          if (!state.attrErrors || state.attrErrors.length === 0) {
            dispatch({
              type: "actionSuccess",
              message: "Product has been updated!",
            });
          }
        },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({
              type: "attributeErrors",
              attrErrors: formatCustomAttrError(
                error.errors[0].message,
                productAttribute.name,
              ),
            });
          } else {
            dispatch({
              type: "attributeErrors",
              attrErrors: formatCustomAttrError(
                `${CUSTOM_ATTR_DEFAULT_ERROR}`,
                productAttribute.name,
              ),
            });
          }
          return error;
        },
      );
  };

  const resetProducts = () => {
    dispatch({
      type: "request",
    });
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    attrErrors: state.attrErrors,
    productCatalogProducts: state.productCatalogProducts,
    meta: state.meta,
    metaProductCatalogProducts: state.metaProductCatalogProducts,
    isSuccess: state.isSuccess,
    message: state.message,
    getProductCatalogProducts,
    removeProductCatalogProductById,
    setProductCatalogProducts,
    editProductCatalogProduct,
    createProductCatalogProduct,
    createAttributeValue,
    editAttributeValue,
    resetProducts,
    setLoading,
    setFailure,
  };
  return (
    <ProductCatalogContext.Provider value={value}>
      {children}
    </ProductCatalogContext.Provider>
  );
};

const useProductCatalogProducts = () => {
  const context = useContext(ProductCatalogContext);

  if (context === undefined) {
    throw new Error(
      "useProductCatalogProducts must be used within ProductsContext",
    );
  }

  return context;
};

export default useProductCatalogProducts;
