import { useState } from "react";
import { useFileUpload } from "hooks/useFileUpload";
import { DropZone } from "components/shared/dropZone";
import { BootstrapButton } from "components/shared/bootstrapButton";
import { modalStyle } from "components/shared/modalStyles";
import { Box, Modal } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DescriptionIcon from "@mui/icons-material/Description";

interface RemoveProductsModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeRemoveProductsModal: () => void;
}

export const RemoveProductsModal = (props: RemoveProductsModalProps) => {
  const [file, setFile] = useState<Blob>();
  const [csvLineCount, setCsvLineCount] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadFile] = useFileUpload();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const openConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleClose = () => {
    setFile(undefined);
    closeConfirmModal();
    props.onClose();
  };

  const onFileAdded = (file: any) => {
    setFile(file);
    const reader = new FileReader();

    reader.onload = () => {
      const text = reader.result?.toString();
      if (text) {
        const lines = text.split("\n").filter((line) => line.trim() !== "");
        setCsvLineCount(lines.length);
      }
    };

    reader.onerror = (err) => {
      console.error(err);
    };

    if (!file) return;
    reader.readAsText(file);
  };

  const handleUpload = (import_type: string) => {
    setIsUploading(true);
    const options = {
      import_type: import_type,
      import_strategies: [],
      context: {},
    };
    uploadFile(file, options).then(() => {
      setIsUploading(false);
    });
  };

  return (
    <div>
      <Modal
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon
              onClick={handleClose}
              className="primary-col pointer"
              style={{ marginTop: "-4px" }}
            />
            <span style={{ marginLeft: "40px" }}>Remove Products via CSV</span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon
                className="primary-col float-left"
                style={{ fontSize: 30 }}
              />
              <p className="m-0 float-left middle-par">
                Example of removing products
              </p>
              <a
                type="button"
                href={`${process.env.REACT_APP_API_BASE_URL}/imports/remove_products.csv`}
                className="btn btn-sm btn-secondary float-right"
              >
                Download
              </a>
            </div>
          </div>
          {!isUploading && !!file && (
            <>
              <BootstrapButton
                variant="contained"
                onClick={() => openConfirmModal()}
              >
                <FileUploadIcon fontSize="small" />
                <label>Upload</label>
              </BootstrapButton>
            </>
          )}
          {isUploading && (
            <>
              <BootstrapButton
                disabled
                variant="contained"
                onClick={() => openConfirmModal()}
              >
                <label>In progress...</label>
              </BootstrapButton>
            </>
          )}
        </Box>
      </Modal>
      {/* Confirm Modal */}
      <Modal
        open={showConfirmModal}
        onClose={closeConfirmModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            <ClearOutlinedIcon
              onClick={closeConfirmModal}
              className="primary-col pointer"
              style={{ marginTop: "-4px" }}
            />
            <span style={{ marginLeft: "40px" }}>Are you sure?</span>
          </h2>
          <div className="clearfix"></div>
          <Box
            sx={{
              marginBottom: "40px",
              marginLeft: "40px",
              marginRight: "40px",
            }}
          >
            <p style={{ marginBottom: "20px" }}>
              You are about to remove <b>{Math.max(csvLineCount - 1, 0)}</b>{" "}
              products.
            </p>
            <p style={{ marginBottom: "10px" }}>
              Products are soft deleted, however following <b>related</b>{" "}
              objects will be deleted permanently:
            </p>
            <ul>
              <li>Attribute Values (inc. Menu Abbreviations)</li>
              <li>Packshot Details</li>
              <li>Product Dimensions</li>
              <li>Normalized UPC Codes</li>
              <li>Customer Product References</li>
              <li>Customer Product List Entries</li>
              <li>
                Labels (inc. Product Items in Predefined Classes and Model
                Lists)
              </li>
            </ul>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
          >
            <BootstrapButton
              variant="contained"
              onClick={() => handleUpload("remove_products")}
            >
              <FileUploadIcon fontSize="small" />
              <span>Upload</span>
            </BootstrapButton>
            <BootstrapButton
              variant="contained"
              onClick={() => closeConfirmModal()}
            >
              <ClearOutlinedIcon fontSize="small" />
              <span>Cancel</span>
            </BootstrapButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default RemoveProductsModal;
