import axios from "axios";
import { SubClassification } from "models/sub_classification";
import { createContext, useReducer, useContext, ReactNode } from "react";
import {
  subClassificationsReducer,
  initialState,
} from "reducers/subClassificationReducer";
import {
  convertFiltersToQueryString,
  Meta,
  generateSortQueryParam,
  GetEntitiesProps,
} from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";

const SubClassificationsContext = createContext(initialState);

interface Props {
  children?: ReactNode;
}

export const SubClassificationsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(subClassificationsReducer, initialState);

  const removeSubclassificationById = (id: string | undefined) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/subclassifications/${id}`)
      .then((res) => {
        dispatch({
          type: "actionSuccess",
          message: "Subclassification has been deleted!",
        });
        setTimeout(() => getSubClassifications({ page: 1, take: 20 }), 2000);
        setTimeout(() => clearActionSuccess(), 5000);
      });
  };

  const getSubClassifications = ({
    page = 1,
    take = 20,
    filters = [],
    sort = [],
  }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading();
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/subclassifications/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`,
      )
      .then(
        (res) => {
          setSubClassifications(
            processDateObjects(res.data.data),
            res.data.meta,
          );
        },
        () => setFailure("Error getting subclassifications"),
      );
  };

  const setSubClassifications = (
    subClassifications: SubClassification[],
    meta: Meta,
  ) => {
    dispatch({
      type: "success",
      results: { subClassifications: subClassifications, meta },
    });
  };

  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" });
  };
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" });
  };

  const createSubClassifications = (subClass: SubClassification) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/subclassifications`, subClass)
      .then(
        (res) => {
          dispatch({
            type: "actionSuccess",
            message: "SubClassification has been created!",
          });

          setTimeout(() => {
            getSubClassifications({ page: 1, take: 20 });
          }, 1000);
          setTimeout(() => clearActionSuccess(), 5000);
        },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message });
          } else {
            dispatch({ type: "failure" });
          }
          setTimeout(() => clearActionFailure(), 5000);
          return error;
        },
      );
  };

  const editSubClassifications = (subClass: SubClassification) => {
    return axios
      .put(
        `${process.env.REACT_APP_API_URL}/subclassifications/${subClass.id}`,
        subClass,
      )
      .then(
        (res) => {
          dispatch({
            type: "actionSuccess",
            message: "SubClassification has been updated!",
          });
          setTimeout(() => {
            getSubClassifications({ page: 1, take: 20 });
          }, 2000);
          setTimeout(() => clearActionSuccess(), 5000);
        },
        (error) => {
          error = error.response.data;
          if (error && error.errors && error.errors[0].message) {
            dispatch({ type: "failure", error: error.errors[0].message });
          } else {
            dispatch({ type: "failure" });
          }
          setTimeout(() => clearActionFailure(), 5000);
          return error;
        },
      );
  };

  const setLoading = () => {
    dispatch({
      type: "request",
    });
  };

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage,
    });
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    subClassifications: state.subClassifications,
    meta: state.meta,
    setSubClassifications: setSubClassifications,
    getSubClassifications: getSubClassifications,
    createSubClassifications: createSubClassifications,
    editSubClassifications: editSubClassifications,
    removeSubclassificationById: removeSubclassificationById,
    setLoading,
    setFailure,
    isSuccess: state.isSuccess,
    message: state.message,
  };
  return (
    <SubClassificationsContext.Provider value={value}>
      {children}
    </SubClassificationsContext.Provider>
  );
};

const useSubClassifications = () => {
  const context = useContext(SubClassificationsContext);

  if (context === undefined) {
    throw new Error(
      "useSubClassifications must be used within SubClassificationsProvider",
    );
  }

  return context;
};

export default useSubClassifications;
