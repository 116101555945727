import { useState, useCallback } from "react";
import { Box, Modal } from "@mui/material";
import ReactCrop, {
  Crop,
  PixelCrop,
  PercentCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

interface PackshotsCroppingModalProps {
  isOpen: boolean;
  onClose: () => void;
  image: string | null;
  onCropConfirm: (croppedImage: Blob) => void;
  currentImageIndex: number | null;
  selectedFiles: any[];
  currentPhoto: any;
  currentProduct: any;
  updatePhoto: (
    productId: string,
    photoId: string,
    formData: FormData,
  ) => Promise<void>;
  setSelectedFiles: React.Dispatch<React.SetStateAction<any[]>>;
  closeCroppingModal: () => void;
}

export const PackshotsCroppingModal = (props: PackshotsCroppingModalProps) => {
  const [crop, setCrop] = useState<Crop | undefined>();
  const [croppedData, setCroppedData] = useState<HTMLCanvasElement | null>(
    null,
  );

  const onImageLoad = useCallback((img: HTMLImageElement) => {
    const { width, height } = img;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "%",
            width: 90,
          },
          16 / 9,
          width,
          height,
        ),
        width,
        height,
      ),
    );
  }, []);

  const handleCropComplete = (percentCrop: PercentCrop) => {
    if (props.image && percentCrop.width && percentCrop.height) {
      const imageElement = new Image();
      imageElement.src = props.image;
      imageElement.crossOrigin = "anonymous";
      imageElement.onload = () => {
        const pixelCrop: PixelCrop = {
          unit: "px",
          x: (percentCrop.x / 100) * imageElement.naturalWidth,
          y: (percentCrop.y / 100) * imageElement.naturalHeight,
          width: (percentCrop.width / 100) * imageElement.naturalWidth,
          height: (percentCrop.height / 100) * imageElement.naturalHeight,
        };

        const canvas = document.createElement("canvas");
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext("2d");

        if (ctx) {
          ctx.drawImage(
            imageElement,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height,
          );
          setCroppedData(canvas);
        }
      };
    }
  };

  const handleCropConfirm = async () => {
    if (croppedData && props.currentImageIndex !== null) {
      try {
        croppedData.toBlob(async (blob) => {
          if (blob) {
            props.onCropConfirm(blob);
            props.closeCroppingModal();
          }
        }, "image/jpeg");
      } catch (error) {
        console.error(
          "Failed to crop the image. The canvas might be tainted.",
          error,
        );
        alert("The image cannot be cropped due to security restrictions.");
      }
    }
  };

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: "800px",
          maxHeight: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {props.image && (
          <>
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={(c, percentCrop) => handleCropComplete(percentCrop)}
            >
              <img
                src={props.image}
                onLoad={(e) => onImageLoad(e.currentTarget)}
                alt="Crop"
                style={{ maxWidth: "100%", maxHeight: "70vh" }}
              />
            </ReactCrop>
            <Box sx={{ marginTop: "20px", display: "flex", gap: "10px" }}>
              <button
                className="btn btn-secondary btn-block fw-bolder"
                onClick={handleCropConfirm}
              >
                Confirm
              </button>
              <button
                className="btn btn-primary btn-block fw-bolder float-end"
                onClick={props.closeCroppingModal}
              >
                Cancel
              </button>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};
