import { Region } from "models/region";
import { Meta } from "models/util";

export interface RegionsState {
  isLoading: boolean;
  regions: Region[];
  meta?: Meta;
  error?: string;
  getRegions?: any;
  editRegion?: any;
  createRegion?: any;
  isSuccess?: boolean;
  message?: string;
  clearActionSuccess?: any;
  removeRegionById?: any;
}

export const initialState: RegionsState = {
  isLoading: true,
  regions: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: "request" }
  | { type: "success"; results: { regions: Region[]; meta: Meta } }
  | { type: "failure"; error: string }
  | { type: "failure"; error?: string }
  | { type: "actionSuccess"; message: string }
  | { type: "clearActionSuccess" }
  | { type: "clearActionFailure" };

export function regionsReducer(
  state: RegionsState,
  action: Action,
): RegionsState {
  switch (action.type) {
    case "request":
      return { ...state, isLoading: true, error: undefined };
    case "success":
      return {
        ...state,
        isLoading: false,
        regions: action.results.regions,
        meta: action.results.meta,
      };
    case "failure":
      return { ...state, isLoading: false, error: action.error };
    case "actionSuccess":
      return {
        ...state,
        isSuccess: true,
        message: action.message,
        error: undefined,
      };
    case "clearActionSuccess":
      return { ...state, isSuccess: false, message: undefined };
    case "clearActionFailure":
      return { ...state, error: undefined };
  }
}
