import { ProductCatalog } from "models/product_catalog";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface ProductCatalogState {
  isLoading: boolean;
  productCatalogs: ProductCatalog[];
  meta?: Meta;
  error?: string;
  getProductCatalogs?: any;
  createProductCatalog?: any;
  editProductCatalog?: any;
  isSuccess?: boolean;
  message?: string;
  clearActionSuccess?: any;
  removeProductCatalogById?: any;
}

export const initialState: ProductCatalogState = {
  isLoading: true,
  productCatalogs: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: "request" }
  | {
      type: "success";
      results: { productCatalogs: ProductCatalog[]; meta: Meta };
    }
  | { type: "failure"; error?: string }
  | { type: "actionSuccess"; message: string }
  | { type: "clearActionSuccess" }
  | { type: "clearActionFailure" };

export function productCatalogReducer(
  state: ProductCatalogState,
  action: Action,
): ProductCatalogState {
  switch (action.type) {
    case "request":
      return { ...state, isLoading: true, error: undefined };
    case "success":
      return {
        ...state,
        isLoading: false,
        productCatalogs: action.results.productCatalogs,
        meta: action.results.meta,
      };
    case "failure":
      return {
        ...state,
        isLoading: false,
        error: action.error || DEFAULT_ERROR,
      };
    case "actionSuccess":
      return {
        ...state,
        isSuccess: true,
        message: action.message,
        error: undefined,
      };
    case "clearActionSuccess":
      return { ...state, isSuccess: false, message: undefined };
    case "clearActionFailure":
      return { ...state, error: undefined };
  }
}
