import { useState, useEffect, SetStateAction } from "react";
import useProducts from "context/productContext";
import { Product } from "../../models/product";
import axios from "axios";
import NiceProgress from "components/niceProgress";
import ImageGallery from "./imageGallery";

import { Box, Modal, Typography, IconButton } from "@mui/material";
import { defaultCenteredModalStyle } from "components/shared/modalStyles";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

interface ProductDetailsProps {
  row: any;
  alreadyExists: boolean;
  alreadyUploaded: boolean;
  notMatched: boolean;
  onNotMatched: () => void;
  onUploaded: () => void;
  onAleadyExists: () => void;
}

export function ProductDetails({
  row,
  alreadyExists,
  alreadyUploaded,
  notMatched,
  onAleadyExists,
  onNotMatched,
  onUploaded,
}: ProductDetailsProps) {
  const [details, setDetails] = useState<Product | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [modalImage, setModalImage] = useState<{
    url: string;
    key: string;
  } | null>(null);
  const [imagesToPersist, setImagesToPersist] = useState<
    Record<string, string>
  >({});
  const [croppedImagesToPersist, setCroppedImagesToPersist] = useState<
    Record<string, string>
  >({});
  const [imagesPreuploadedForCropping, setImagesPreuploadedForCropping] =
    useState<string[]>([]);
  const [imagesBlobIds, setImagesBlobIds] = useState<Record<string, string>>(
    {},
  );
  const [showCroppedImages, setShowCroppedImages] = useState<boolean>(false);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedPreviewImg, setSelectedPreviewImg] = useState("");

  const [selectedCroppedPreviewImg, setSelectedCroppedPreviewImg] =
    useState("");
  const [isCroppedPreviewModalOpen, setIsCroppedPreviewModalOpen] =
    useState(false);

  const uuid = row["UUID"];

  const { products, meta, getProducts, addPhotosToProduct, isLoading } =
    useProducts();

  const openCroppedPreviewModal = (imgSrc: string) => {
    setSelectedCroppedPreviewImg(imgSrc);
    setIsCroppedPreviewModalOpen(true);
  };

  const closeCroppedPreviewModal = () => {
    setSelectedCroppedPreviewImg("");
    setIsCroppedPreviewModalOpen(false);
  };

  useEffect(() => {
    setImagesToPersist({});
    setImagesPreuploadedForCropping([]);
    setImagesBlobIds({});
    const initialImages: Record<string, string> = {};
    const fetchPackshotImages = async () => {
      for (let i = 1; i <= 6; i++) {
        const packshotKey = `Packshot ${i}`;
        const packshotUrl = row[packshotKey];
        if (packshotUrl) {
          initialImages[packshotKey] = packshotUrl;
        }
      }
      setImagesToPersist(initialImages);
    };

    if (typeof uuid === "string" && uuid !== "") {
      const filters = [{ field: "id", value: uuid }];
      getProducts({ page: 1, filters: filters });
      fetchPackshotImages();
    }
  }, [uuid]);

  useEffect(() => {
    const uploadFilesFromUrls = async () => {
      const uploadPromises = Object.entries(imagesToPersist).map(
        ([key, photoUrl]) => {
          return axios
            .post(
              `${process.env.REACT_APP_API_URL}/photos`,
              { photo_url: photoUrl },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              },
            )
            .then((res) => {
              const key = res.data.data.key;
              const blobId = res.data.data.id;
              const fastlyUrl = `${process.env.REACT_APP_FASTLY_BASE_URL}/${key}?trim-color=auto`;

              setImagesPreuploadedForCropping((prev) => [...prev, fastlyUrl]);
              setImagesBlobIds((prev) => ({ ...prev, [key]: blobId }));

              return { key, fastlyUrl, blobId };
            })
            .catch((error) => {
              console.error(`Error uploading ${key}: `, error);
              return null;
            });
        },
      );

      const results = await Promise.all(uploadPromises);
      const validResults = results.filter((result) => result !== null);

      if (validResults.length > 0) {
        const croppedUrls = validResults.reduce<Record<string, string>>(
          (acc, result) => {
            if (result) acc[result.key] = result.fastlyUrl;
            return acc;
          },
          {},
        );
        setCroppedImagesToPersist(croppedUrls);
      }
    };

    if (Object.keys(imagesToPersist).length > 0) {
      uploadFilesFromUrls();
    }
  }, [imagesToPersist]);

  useEffect(() => {
    if (!isLoading) {
      if (products && products.length > 0) {
        const firstProduct = products[0];
        setDetails(firstProduct);
      } else {
        setDetails(null);
        onNotMatched();
      }
    }
  }, [products]);

  const handleImageClick = (imageUrl: string, key: string) => {
    setModalImage({ url: imageUrl, key });
  };

  const handleCroppedImage = (croppedImageBlob: Blob) => {
    if (modalImage) {
      const newImageUrl = URL.createObjectURL(croppedImageBlob);
      setDetails((prevDetails) => {
        // if (prevDetails) {
        //   return {
        //     ...prevDetails,
        //     photos: prevDetails.photos?.map((photo: any) =>
        //       photo.key === modalImage.key ? { ...photo, original_url: newImageUrl } : photo
        //     )
        //   };
        // }
        return prevDetails;
      });
      setModalImage(null);
    }
  };

  const handleCroppedPackshotsUpload = () => {
    if (details && details.id) {
      uploadFiles(true);
    }
  };

  const handleOriginalPackshotsUpload = () => {
    if (details && details.id) {
      uploadFiles(false);
    }
  };

  const uploadFile = (blobId: string, productId: string) => {
    addPhotosToProduct(productId, [blobId]).then(() => {
      onUploaded();
    });
  };

  const uploadFiles = async (uploadCropped: boolean) => {
    if (!details || !details.id) return;

    if (uploadCropped) {
      const croppedUploadPromises = Object.entries(croppedImagesToPersist).map(
        async ([key, fastlyUrl]) => {
          try {
            const uploadResponse = await axios.post(
              `${process.env.REACT_APP_API_URL}/photos`,
              { photo_url: fastlyUrl },
              {
                headers: { "Content-Type": "multipart/form-data" },
              },
            );

            const blobId = uploadResponse.data.data.id;
            await addPhotosToProduct(details.id, [blobId]);
          } catch (error) {
            console.error(`Error uploading cropped image for ${key}:`, error);
          }
        },
      );

      await Promise.all(croppedUploadPromises);
      onUploaded();
    } else {
      const originalUploadPromises = Object.entries(imagesBlobIds).map(
        async ([key, blobId]) => {
          try {
            await addPhotosToProduct(details.id, [blobId]);
          } catch (error) {
            console.error(`Error adding pre-uploaded image for ${key}:`, error);
          }
        },
      );

      await Promise.all(originalUploadPromises);
      onUploaded();
    }
  };

  const openProductDetails = () => {
    if (details && details.id) {
      window.open(
        `/products/${details.id}`,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes",
      );
      return false;
    }
  };

  const handleAlreadyExistsClick = () => {
    onAleadyExists();
  };

  const openPreviewModal = (imgSrc: SetStateAction<string>) => {
    setSelectedPreviewImg(imgSrc);
    setIsPreviewModalOpen(true);
  };

  const closePreviewModal = () => {
    setSelectedPreviewImg("");
    setIsPreviewModalOpen(false);
  };

  return (
    <>
      {isLoading && <NiceProgress basePhrase="Matching in progress..." />}
      {!isLoading && error && <p>Error: {error}</p>}
      {!isLoading && !details && (
        <p>Oopsie woopsie! It seems like there is no match.</p>
      )}
      {!isLoading && !error && details && (
        <>
          <h5 className="card-title mb-1">Brandbank Data</h5>
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <table className="table table-sm table-bordered">
                <tbody>
                  {[
                    { label: "Full Name", value: details.full_name },
                    {
                      label: "Label Name",
                      value: details?.labels?.map((label: any) => label.name),
                    },
                    { label: "UUID", value: details.id },
                  ].map(({ label, value }) => (
                    <tr key={label}>
                      <th
                        className="bg-light text-secondary"
                        style={{ width: "30%", fontSize: "0.9rem" }}
                      >
                        {label}
                      </th>
                      <td className="text-muted" style={{ fontSize: "0.9rem" }}>
                        {value ?? "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {details && details.id && (
                <div className="row mt-3 justify-content-end">
                  <div className="col-auto">
                    <button
                      className="btn btn-primary"
                      onClick={() => openProductDetails()}
                    >
                      More details
                    </button>
                  </div>
                </div>
              )}

              <div
                className="row mt-3"
                style={{
                  overflowX: "visible",
                  overflowY: "scroll",
                }}
              >
                {Object.keys(details.photos || {}).length > 0 ? (
                  <ImageGallery
                    photos={details.photos}
                    handleImageClick={(originalUrl, key) =>
                      handleImageClick(originalUrl, key)
                    }
                  />
                ) : (
                  <div className="col-12">
                    <p className="text-muted">
                      It looks like we're missing some packshots for this
                      product. Let's get some uploaded!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <h5 className="card-title mt-1 mb-1">Packshots to upload</h5>
          <div className="card shadow-sm mb-2">
            <div className="card-body">
              <div className="row mt-3">
                <div className="row mt-3 justify-content-end">
                  <div className="col-auto">
                    <button
                      className={`btn ${showCroppedImages ? "btn-warning" : "btn-primary"}`}
                      onClick={() =>
                        setShowCroppedImages((prevState) => !prevState)
                      }
                    >
                      {showCroppedImages
                        ? "Show Original Images"
                        : "Show Cropped Images"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                {!showCroppedImages &&
                  Object.entries(imagesToPersist).map(([key, url]) => {
                    return (
                      <div key={key} className="col-md-4 mb-3">
                        <img
                          src={url}
                          alt={`${key}`}
                          className="img-fluid"
                          style={{
                            maxWidth: "100%",
                            cursor: "pointer",
                            border: "1px solid rgba(221, 221, 221, 0.5)",
                          }}
                          onClick={() => openPreviewModal(url)}
                        />
                      </div>
                    );
                  })}

                {showCroppedImages &&
                  Object.entries(imagesPreuploadedForCropping).map(
                    ([key, url]) => (
                      <div key={key} className="col-md-4 mb-3">
                        <img
                          src={url}
                          alt={key}
                          className="img-fluid"
                          style={{
                            maxWidth: "100%",
                            cursor: "pointer",
                            border: "1px solid rgba(221, 221, 221, 0.5)",
                          }}
                          onClick={() => openCroppedPreviewModal(url)}
                        />
                      </div>
                    ),
                  )}
              </div>
            </div>
          </div>

          <div className="text-end">
            <button
              className={`btn ${alreadyUploaded ? "btn-success" : "btn-primary"}`}
              onClick={() => handleOriginalPackshotsUpload()}
              disabled={alreadyUploaded}
            >
              {alreadyUploaded ? "Uploaded" : "Upload Original Packshots"}
            </button>

            <button
              className={`btn ${alreadyUploaded ? "btn-success" : "btn-primary"} ms-2`}
              onClick={() => handleCroppedPackshotsUpload()}
              disabled={alreadyUploaded}
            >
              {alreadyUploaded ? "Uploaded" : "Upload Cropped Packshots"}
            </button>
            <button
              className={`btn ${alreadyExists ? "btn-warning" : "btn-secondary"} ms-2`}
              onClick={() => handleAlreadyExistsClick()}
              disabled={alreadyExists}
            >
              {alreadyExists ? "Marked as Existing" : "Already Exists"}
            </button>
          </div>

          <Modal
            open={isPreviewModalOpen}
            onClose={closePreviewModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                ...defaultCenteredModalStyle,
                maxHeight: "90vh",
                overflowY: "auto",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography id="image-modal-title" variant="h6" component="h2">
                  Image Preview
                </Typography>
                <IconButton
                  onClick={closePreviewModal}
                  aria-label="Close Modal"
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </Box>
              <Box display="flex" justifyContent="center">
                <img
                  src={selectedPreviewImg}
                  alt="Preview image"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Box>
            </Box>
          </Modal>
          <Modal
            open={isCroppedPreviewModalOpen}
            onClose={closeCroppedPreviewModal}
            aria-labelledby="cropped-modal-title"
            aria-describedby="cropped-modal-description"
          >
            <Box
              sx={{
                ...defaultCenteredModalStyle,
                maxHeight: "90vh",
                overflowY: "auto",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  id="cropped-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Cropped Image Preview
                </Typography>
                <IconButton
                  onClick={closeCroppedPreviewModal}
                  aria-label="Close Modal"
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </Box>
              <Box display="flex" justifyContent="center">
                <img
                  src={selectedCroppedPreviewImg}
                  alt="Cropped preview image"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
