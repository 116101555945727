import { Classification } from "models/classification";
import { Meta } from "models/util";

export interface ClassificationsState {
  isLoading: boolean;
  classifications: Classification[];
  meta?: Meta;
  error?: string;
  getClassifications?: any;
  editClassifications?: any;
  createClassifications?: any;
  isSuccess?: boolean;
  message?: string;
  clearActionSuccess?: any;
  removeClassificationById?: any;
}

export const initialState: ClassificationsState = {
  isLoading: true,
  classifications: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

type Action =
  | { type: "request" }
  | {
      type: "success";
      results: { classifications: Classification[]; meta: Meta };
    }
  | { type: "failure"; error?: string }
  | { type: "actionSuccess"; message: string }
  | { type: "clearActionSuccess" }
  | { type: "clearActionFailure" };

export function classificationsReducer(
  state: ClassificationsState,
  action: Action,
): ClassificationsState {
  switch (action.type) {
    case "request":
      return { ...state, isLoading: true, error: undefined };
    case "success":
      return {
        ...state,
        isLoading: false,
        classifications: action.results.classifications,
        meta: action.results.meta,
      };
    case "failure":
      return { ...state, isLoading: false, error: action.error };
    case "actionSuccess":
      return {
        ...state,
        isSuccess: true,
        message: action.message,
        error: undefined,
      };
    case "clearActionSuccess":
      return { ...state, isSuccess: false, message: undefined };
    case "clearActionFailure":
      return { ...state, error: undefined };
  }
}
