import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "@mui/material";

const LoginPage = () => {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    error,
    logout,
  } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) return;

    if (isLoading) return;

    if (isAuthenticated) {
      navigate("/products", { replace: true });
    } else {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, error, navigate, loginWithRedirect]);

  if (error) {
    return (
      <>
        <Alert className="m-5" severity="error">
          <strong>Unauthorized: You don't have access.</strong> Please contact
          your administrator
        </Alert>
        <button
          onClick={() => logout()}
          type="button"
          className="m-5 btn btn-sm btn-secondary"
        >
          Logout
        </button>
      </>
    );
  }

  return <p>Loading...</p>;
};

export default LoginPage;
