import { GridCellProps } from "@progress/kendo-react-grid";
import { NavLink } from "react-router-dom";
const uuid = require("uuid");

export const LabelCell = ({
  dataItem,
  target = "_blank",
}: GridCellProps & { target?: string }) => {
  return (
    <td>
      {dataItem?.labels?.map((label: { name: string; id: string }) => (
        <NavLink
          key={uuid.v4()}
          to={`/labels/${label.id}`}
          replace={true}
          target={target}
        >
          {label.name}
        </NavLink>
      ))}
    </td>
  );
};
