import { SortDescriptor } from "@progress/kendo-data-query";
import { GridProps } from "@progress/kendo-react-grid";

export const gridSettings: GridProps = {
  resizable: true,
  reorderable: true,
  filterable: true,
  sortable: {
    allowUnsort: false,
    mode: "single",
  },
  pageable: {
    pageSizes: [20, 50, 100],
    buttonCount: 7,
    previousNext: true,
  },
};

export const initialSort: Array<SortDescriptor> = [];
