import { Product } from "models/product";
import { DEFAULT_ERROR, Meta } from "models/util";

export interface ProductsState {
  isLoading: boolean;
  products: Product[];
  meta?: Meta;
  error?: string;
  attrErrors?: string[];
  getProducts?: any;
  createProduct?: any;
  editProduct?: any;
  addPhotoToProduct?: any;
  addPhotosToProduct?: any;
  makePhotoPrimary?: any;
  updatePhoto?: any;
  deleteAllPhotos?: any;
  deletePhoto?: any;
  editAttributeValue?: any;
  createAttributeValue?: any;
  isSuccess?: boolean;
  message?: string;
  clearActionSuccess?: any;
  removeProductbyId?: any;
}

export const initialState: ProductsState = {
  isLoading: true,
  products: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
};

const addAttrErrors = (state: ProductsState, newAttrErrors: string[]) => {
  if (state.attrErrors && state.attrErrors.length > 0) {
    return [...state.attrErrors, ...newAttrErrors];
  }
  return newAttrErrors;
};

type Action =
  | { type: "request" }
  | { type: "success"; results: { products: Product[]; meta?: Meta } }
  | { type: "failure"; error?: string }
  | { type: "actionSuccess"; message: string }
  | { type: "clearActionSuccess" }
  | { type: "clearActionFailure" }
  | { type: "attributeErrors"; attrErrors: string[] };

export function productReducer(
  state: ProductsState,
  action: Action,
): ProductsState {
  switch (action.type) {
    case "request":
      return { ...state, isLoading: true, error: undefined };
    case "success":
      return {
        ...state,
        isLoading: false,
        products: action.results.products,
        meta: action.results.meta,
      };
    case "failure":
      return {
        ...state,
        isLoading: false,
        error: action.error || DEFAULT_ERROR,
      };
    case "actionSuccess":
      return {
        ...state,
        isSuccess: true,
        message: action.message,
        error: undefined,
      };
    case "clearActionSuccess":
      return {
        ...state,
        isSuccess: false,
        message: undefined,
        attrErrors: undefined,
      };
    case "clearActionFailure":
      return { ...state, error: undefined };
    case "attributeErrors":
      return { ...state, attrErrors: addAttrErrors(state, action.attrErrors) };
  }
}
