import CheckIcon from "@mui/icons-material/Check";

interface SaveButtonProps {
  disabled?: boolean;
}

export const SaveButton = (props: SaveButtonProps) => (
  <button
    className="save-ui btn btn-primary btn-block fw-bolder float-end"
    disabled={props.disabled}
    type="submit"
  >
    <CheckIcon fontSize="small" /> Save{" "}
  </button>
);
